import { Flex, Icon, Button, Box } from "@chakra-ui/react";

import React from "react";

import { MdOutlineClose } from "react-icons/md";
import { getAttrOfItemBykey } from "../../../utils/object";

import { FilterType } from "../types";
import { getDisabledState } from "../utils/getDisabledState";
import FilterModalInputTypeString from "./Types/String";
import FilterModalInputTypeNumber from "./Types/Number";
import FilterModalInputTypeDate from "./Types/Date";
import FilterModalInputTypeSelect from "./Types/Select";
import { capitalizeFirstLetter } from "../../../utils/string";

interface FilterModalInputProps {
  messages: any;
  filterModalInputTop: string;
  filterModalInputLeft: string;
  selected_filter_key: string;
  selected_filter_name: string;
  set_local_filters: React.Dispatch<React.SetStateAction<FilterType[] | null>>;
  set_is_filter_menu_input_closed: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  filters: FilterType[] | null;
  local_filters: FilterType[] | null;
  on_filter: (local_filters: any, clear?: boolean | undefined) => void;
}

const FilterModalInput: React.FC<FilterModalInputProps> = ({
  messages,
  filterModalInputTop,
  filterModalInputLeft,
  selected_filter_key,
  selected_filter_name,
  set_local_filters,
  set_is_filter_menu_input_closed,
  filters,
  local_filters,
  on_filter,
}) => {
  const current_type = getAttrOfItemBykey(
    selected_filter_key,
    local_filters,
    "type"
  );

  return (
    <Flex
      outline="none"
      minW="256px"
      borderRadius="4px"
      boxShadow="0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%)"
      top={filterModalInputTop}
      left={filterModalInputLeft}
      position="fixed"
      bg="white"
      zIndex="202"
    >
      <Flex bg="white" flex="1" zIndex="203" direction="column">
        <Flex alignItems="center" flex="1" color="white" bg="#1f1f1f">
          <Flex flex="1" p="11px 0 13px 16px">
            {capitalizeFirstLetter(selected_filter_name)}
          </Flex>
          <span
            onClick={() => {
              set_local_filters(filters);
              set_is_filter_menu_input_closed(true);
            }}
            title="Close"
          >
            <Icon
              mx="16px"
              cursor="pointer"
              color="gray"
              _hover={{ color: "white" }}
              as={MdOutlineClose}
              w="6"
              h="6"
            />
          </span>
        </Flex>

        {["select"].includes(current_type) && (
          <>
            <Flex direction="column" w="100%" pt="14px" px="16px">
              <FilterModalInputTypeSelect
                messages={messages}
                selected_filter_key={selected_filter_key}
                local_filters={local_filters}
                set_local_filters={set_local_filters}
                on_filter={on_filter}
              />
            </Flex>
            <Flex justifyContent="flex-end" p="8px">
              <Button
                disabled={getDisabledState(
                  selected_filter_key,
                  local_filters,
                  filters
                )}
                onClick={() => {
                  on_filter(local_filters);
                }}
                rounded="0"
                variant="unstyled"
                className="ripple"
                p="8px"
              >
                {messages["apply"].toUpperCase()}
              </Button>
            </Flex>
          </>
        )}

        {["date"].includes(current_type) && (
          <>
            <Flex direction="column" w="100%" pt="14px" px="16px">
              <FilterModalInputTypeDate
                messages={messages}
                selected_filter_key={selected_filter_key}
                local_filters={local_filters}
                set_local_filters={set_local_filters}
                on_filter={on_filter}
              />
            </Flex>
            <Flex justifyContent="flex-end" p="8px">
              {getAttrOfItemBykey(
                selected_filter_key,
                local_filters,
                "search_type"
              ) !== "default" && (
                <Button
                  disabled={getDisabledState(
                    selected_filter_key,
                    local_filters
                  )}
                  onClick={() => {
                    on_filter(local_filters);
                  }}
                  rounded="0"
                  variant="unstyled"
                  className="ripple"
                  p="8px"
                >
                  {messages["apply"].toUpperCase()}
                </Button>
              )}
            </Flex>
          </>
        )}

        {["string", "number"].includes(current_type) && (
          <>
            <Flex direction="column" w="100%" pt="14px" px="16px">
              {current_type === "string" && (
                <FilterModalInputTypeString
                  messages={messages}
                  selected_filter_key={selected_filter_key}
                  local_filters={local_filters}
                  set_local_filters={set_local_filters}
                  on_filter={on_filter}
                />
              )}
              {current_type === "number" && (
                <FilterModalInputTypeNumber
                  messages={messages}
                  selected_filter_key={selected_filter_key}
                  local_filters={local_filters}
                  set_local_filters={set_local_filters}
                  on_filter={on_filter}
                />
              )}
            </Flex>
            <Flex justifyContent="flex-end" p="8px">
              <Button
                disabled={getDisabledState(selected_filter_key, local_filters)}
                onClick={() => {
                  on_filter(local_filters);
                }}
                rounded="0"
                variant="unstyled"
                className="ripple"
                p="8px"
              >
                {messages["apply"].toUpperCase()}
              </Button>
            </Flex>
          </>
        )}
      </Flex>
      <Box
        onClick={() => {
          set_local_filters(filters);
          set_is_filter_menu_input_closed(true);
        }}
        onWheel={() => {
          set_local_filters(filters);
          set_is_filter_menu_input_closed(true);
        }}
        bg="rgba(0, 0, 0, .85)"
        zIndex="202"
        top="0"
        right="0"
        bottom="0"
        left="0"
        position="fixed"
        w="100vw"
        h="100vh"
      ></Box>
    </Flex>
  );
};

export default FilterModalInput;
