import { Input } from "@chakra-ui/input";
import { Flex, Text } from "@chakra-ui/layout";
import { Icon } from "@chakra-ui/react";
import React from "react";
import { MdFilterList, MdSearch } from "react-icons/md";
import RippleButton from "../RippleButton";

interface SimpleFilterComponentProps {
  row_height: number;
  label: string;
  search: string;
  set_search: React.Dispatch<React.SetStateAction<string>>;
  on_search: (clear?: boolean) => void;
}

const SimpleFilterComponent: React.FC<SimpleFilterComponentProps> = ({
  row_height,
  label,
  search,
  set_search,
  on_search,
}) => {
  return (
    <Flex
      borderBottomWidth=".125px"
      borderBottomStyle="solid"
      borderBottomColor="gray.200"
      alignItems="center"
      mt="23px"
      h={`${row_height}px`}
    >
      <Flex
        as="label"
        cursor="pointer"
        htmlFor="filter-input"
        h={`${row_height}px`}
        p="8px"
        m="0px 8px 0px 16px"
      >
        <Icon color="gray" w="6" h="6" as={MdFilterList} />
      </Flex>

      <Flex
        alignItems="center"
        position="relative"
        minW="120px"
        mx="32px"
        h={`${row_height}px`}
      >
        <Text>{label} : </Text>
      </Flex>

      <Flex
        border="0.125px solid var(--chakra-colors-gray-200)"
        borderBottomRadius="0"
        alignItems="center"
        position="relative"
        w="400px"
        h={`${row_height}px`}
      >
        <Input
          value={search}
          onChange={(e) => {
            if (e.target.value === "") {
              set_search("");
              on_search(true);
            } else {
              set_search(e.target.value);
            }
          }}
          px="8px"
          mr="8px"
          id="filter-input"
          height={`${row_height}px`}
          outline="0"
          variant="unstyled"
          type="search"
          name="filter"
        />
        <RippleButton
          onClick={() => on_search()}
          disabled={search.length === 0}
          borderLeft="0.125px solid var(--chakra-colors-gray-200)"
          variant="unstyled"
        >
          <Icon color="brand.500" as={MdSearch} w="6" h="6" />
        </RippleButton>
      </Flex>
    </Flex>
  );
};

export default SimpleFilterComponent;
