import React from "react";
import { Flex, Select, Button } from "@chakra-ui/react";

import moment from "moment";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/da";
import "moment/locale/hu";
import "moment/locale/nn";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/fi";
import "moment/locale/sv";

import Calendar from "react-calendar";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import {
  getAttrOfItemBykey,
  getValueOfItemBykey,
} from "../../../../../utils/object";
import { date_filter_search_types } from "../../../constants";
import { FilterType } from "../../../types";
import { updateFilterSearchType, updateFilterValue } from "../utils";
import useLang from "../../../../../hooks/useLang";

interface FilterModalInputTypeDateProps {
  selected_filter_key: string;
  messages: any;
  local_filters: FilterType[] | null;
  set_local_filters: React.Dispatch<React.SetStateAction<FilterType[] | null>>;
  on_filter: (local_filters: any, clear?: boolean | undefined) => void;
}

const FilterModalInputTypeDate: React.FC<FilterModalInputTypeDateProps> = ({
  messages,
  selected_filter_key,
  local_filters,
  set_local_filters,
  on_filter,
}) => {
  const lang = useLang();
  moment.locale(lang === "no" ? "nn" : lang);

  const current_search_type = getAttrOfItemBykey(
    selected_filter_key,
    local_filters,
    "search_type"
  );

  const current_value = getValueOfItemBykey(selected_filter_key, local_filters);
  // console.log("current_value=>", current_value);

  return (
    <Flex direction="column">
      <Flex w="100%" pb="8px">
        <Select
          defaultValue={current_search_type}
          w="100%"
          onChange={(e) =>
            e.target.value &&
            updateFilterSearchType(
              e.target.value,
              selected_filter_key,
              local_filters,
              set_local_filters
            )
          }
        >
          {date_filter_search_types.map((el) => (
            <option key={`key-${el}`} value={`${el}`}>
              {messages[el]}
            </option>
          ))}
        </Select>
      </Flex>

      {["equal", "not_equal", "gt", "gte", "lt", "lte"].includes(
        current_search_type
      ) && (
        <Flex w="100%">
          <Calendar
            locale={lang}
            prev2Label={null}
            next2Label={null}
            value={
              current_value && current_value.length <= 8
                ? moment(current_value, "YYYYMMDD").locale("en").toDate()
                : moment().locale("en").toDate()
            }
            onChange={(value: any) => {
              const value__ = moment(value).locale("en").format("YYYYMMDD");
              updateFilterValue(
                value__,
                selected_filter_key,
                local_filters,
                set_local_filters
              );
            }}
          />
        </Flex>
      )}

      {["in", "out"].includes(current_search_type) && (
        <Flex w="100%">
          <DateRangePicker
            locale={lang}
            prev2Label={null}
            next2Label={null}
            clearIcon={null}
            rangeDivider="&nbsp;&nbsp;&nbsp;&nbsp;_&nbsp;&nbsp;&nbsp;&nbsp;"
            format="dd/MM/yyyy"
            value={
              current_value && current_value.length > 8
                ? [
                    moment(JSON.parse(current_value)[0], "YYYYMMDD").locale("en").toDate(),
                    moment(JSON.parse(current_value)[1], "YYYYMMDD").locale("en").toDate(),
                  ]
                : [moment().locale("en").toDate(), moment().locale("en").add(1, "days").toDate()]
            }
            onChange={(value: any) => {
              const value__ = JSON.stringify([
                moment(value[0]).locale("en").format("YYYYMMDD"),
                moment(value[1]).locale("en").format("YYYYMMDD"),
              ]);
              updateFilterValue(
                value__,
                selected_filter_key,
                local_filters,
                set_local_filters
              );
            }}
          />
        </Flex>
      )}
      {current_search_type === "default" && (
        <Flex w="100%">
          <Flex w="100%" direction="column">
            {["yesterday", "last_week", "last_month"].map((el) => (
              <Button
                borderWidth={current_value === el ? "3px" : ""}
                disabled={current_value === el}
                key={el}
                onClick={() => {
                  updateFilterValue(
                    el,
                    selected_filter_key,
                    local_filters,
                    on_filter
                  );
                }}
                colorScheme="yellow"
                width="full"
                rounded="0"
                variant="solid"
                className="ripple"
                p="8px"
                mb="8px"
              >
                {messages[el]}
              </Button>
            ))}
          </Flex>

          <Flex w="100%" direction="column">
            {["today", "this_week", "this_month"].map((el) => (
              <Button
                borderWidth={current_value === el ? "3px" : ""}
                disabled={current_value === el}
                key={el}
                onClick={() => {
                  updateFilterValue(
                    el,
                    selected_filter_key,
                    local_filters,
                    on_filter
                  );
                }}
                colorScheme="blue"
                width="full"
                rounded="0"
                variant="solid"
                className="ripple"
                p="8px"
                mb="8px"
              >
                {messages[el]}
              </Button>
            ))}
          </Flex>

          <Flex w="100%" direction="column">
            {["tomorrow", "next_week", "next_month"].map((el) => (
              <Button
                borderWidth={current_value === el ? "3px" : ""}
                disabled={current_value === el}
                key={el}
                onClick={() => {
                  updateFilterValue(
                    el,
                    selected_filter_key,
                    local_filters,
                    on_filter
                  );
                }}
                colorScheme="green"
                width="full"
                rounded="0"
                variant="solid"
                className="ripple"
                p="8px"
                mb="8px"
              >
                {messages[el]}
              </Button>
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default FilterModalInputTypeDate;
