const getLangByCode = (code: string) => {
  const languages = [
    { name: "Nederlands", code: "nl" },
    { name: "Français", code: "fr" },
    { name: "Deutsch", code: "de" },
    { name: "English", code: "en" },
    { name: "Español", code: "es" },
    { name: "Italiano", code: "it" },
    { name: "Dansk", code: "da" },
    { name: "Magyar", code: "hu" },
    { name: "Norsk", code: "no" },
    { name: "Polski", code: "pl" },
    { name: "Português", code: "pt" },
    { name: "Suomi", code: "fi" },
    { name: "Svenska", code: "sv" },
  ];

  return languages.filter((el) => el.code === code)[0].name;
};

export default getLangByCode;
