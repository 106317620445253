import ReservationsPage from "..";
import useMessagesLocalStorage from "../../../hooks/useMessagesLocalStorage";

const ReservationsPageLangContainer = (props: any) => {
  const messages = useMessagesLocalStorage(`/lang/pages/reservations/`);

  if (messages === "") {
    return <div>loarding...</div>;
  } else {
    return <ReservationsPage {...props} messages={JSON.parse(messages)} />;
  }
};

export default ReservationsPageLangContainer;
