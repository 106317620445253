import Icon from "@chakra-ui/icon";
import { Flex } from "@chakra-ui/layout";
import React from "react";
import { MdAdd } from "react-icons/md";

import RippleButton from "../../Components/RippleButton";

import FormComponent from "./form";

interface AddProductComponentProps {
  messages: any;
}

const AddProductComponent: React.FC<AddProductComponentProps> = ({
  messages,
}) => {
  return (
    <FormComponent messages={messages} type="create">
      <RippleButton
        ml="32px"
        px="16px"
        variant="unstyled"
        rounded="0"
        color="brand.500"
      >
        <Flex px="16px">
          <Icon as={MdAdd} w="6" h="6" mr="4px" />{" "}
          <span>{messages["add_products"].toUpperCase()}</span>
        </Flex>
      </RippleButton>
    </FormComponent>
  );
};

export default AddProductComponent;
