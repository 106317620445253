import { useQuery } from "@apollo/client";
import React from "react";
import AvailabilityPage from "..";
import { GET_BLOCKED_DATES_QUERY } from "../../../Apollo/typedefs";
import NProgressComponent from "../../../App/Layout/NProgress";
import DisplayErrorLangContainer from "../../../Components/DisplayError/containers/lang";
import { decryptObject, encryptObject } from "../../../utils/crypt";

let challenge = encryptObject({
  date: new Date(),
});

interface AvailabilityContainerProps {
  messages: any;
}
const AvailabilityContainer: React.FC<AvailabilityContainerProps> = ({
  messages,
}) => {
  const { loading, error, data } = useQuery(GET_BLOCKED_DATES_QUERY, {
    variables: { challenge },
  });

  if (loading) {
    return <NProgressComponent />;
  }

  if (error) {
    return <DisplayErrorLangContainer error={error} />;
  }

  if (data && data.getBlockedDates) {
    const res_blocked_dates = decryptObject(data.getBlockedDates);
    if (!res_blocked_dates) {
      return <div>error *176</div>;
    }

    if (!res_blocked_dates.blocked_dates) {
      return <div>error *484</div>;
    }

    return (
      <AvailabilityPage
        blocked_dates={res_blocked_dates.blocked_dates}
        messages={messages}
      />
    );
  } else {
    return <div>error *179</div>;
  }
};

export default AvailabilityContainer;
