import { gql } from "@apollo/client";

export const CURRENT_USER_QUERY = gql`
  query CURRENT_USER_QUERY {
    me
  }
`;

export const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION($challenge: String!) {
    signin(challenge: $challenge)
  }
`;

export const SIGN_OUT_MUTATION = gql`
  mutation SIGN_OUT_MUTATION {
    logout
  }
`;

export const PRICES_QUERY = gql`
  query PRICES_QUERY($challenge: String!) {
    prices(challenge: $challenge)
  }
`;

export const PRICE_BY_ID_QUERY = gql`
  query PRICE_BY_ID_QUERY($challenge: String!) {
    priceById(challenge: $challenge)
  }
`;

export const CREATE_PRICE = gql`
  mutation CREATE_PRICE($challenge: String!) {
    createPrice(challenge: $challenge)
  }
`;

export const UPDATE_PRICE = gql`
  mutation UPDATE_PRICE($challenge: String!) {
    updatePrice(challenge: $challenge)
  }
`;

export const DELETE_PRICE = gql`
  mutation DELETE_PRICE($challenge: String!) {
    deletePrice(challenge: $challenge)
  }
`;

export const GET_BLOCKED_DATES_QUERY = gql`
  query GET_BLOCKED_DATES_QUERY($challenge: String!) {
    getBlockedDates(challenge: $challenge)
  }
`;

export const SAVE_BLOCKED_DATE_MUTATION = gql`
  mutation SAVE_BLOCKED_DATE_MUTATION($challenge: String!) {
    saveBlockedDate(challenge: $challenge)
  }
`;

export const PRODUCTS_QUERY = gql`
  query PRODUCTS_QUERY($challenge: String!) {
    products(challenge: $challenge)
  }
`;

export const GET_PRODUCTS_BY_ARRAY_IDS = gql`
  query GET_PRODUCTS_BY_ARRAY_IDS($challenge: String!) {
    getProductsByArrayIds(challenge: $challenge)
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CREATE_PRODUCT($challenge: String!) {
    createProduct(challenge: $challenge)
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UPDATE_PRODUCT($challenge: String!) {
    updateProduct(challenge: $challenge)
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DELETE_PRODUCT($challenge: String!) {
    deleteProduct(challenge: $challenge)
  }
`;

export const RESERVATIONS_QUERY = gql`
  query RESERVATIONS_QUERY($challenge: String!) {
    reservations(challenge: $challenge)
  }
`;

export const GET_SALES_BY_TIME = gql`
  query GET_SALES_BY_TIME($challenge: String!) {
    getSalesByTime(challenge: $challenge)
  }
`;
