import styled from "styled-components";

export const ResponsiveTableStyles = styled.div`
  width: 100%;

  & td .tdBefore {
    display: none;
  }

  & tr#STANDARD td {
    background-color: var(--chakra-colors-brand-50);
  }

  & tbody tr:nth-of-type(odd) td {
    border-right-color: var(--chakra-colors-gray-300) !important;
  }

  @media screen and (max-width: 40em) {
    /*
  Force table elements to not behave like tables anymore
  Hide table headers (but not display: none; for accessibility)
*/

    & table,
    & thead,
    & tbody,
    & th,
    & td,
    & tr {
      display: block;
    }

    & thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    & tbody tr {
      padding: 0.25em;
    }

    & td.pivoted {
      /* Behave like a "row" */

      border: none !important;
      position: relative;
      padding-left: calc(50% + 10px) !important;
      text-align: left !important;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      border-bottom: 1px solid lightgrey !important;
    }

    & td .tdBefore {
      /* Now like a table header */
      position: absolute;
      display: block;

      /* Top/left values mimic padding */
      left: 1rem;
      width: calc(50% - 20px);
      white-space: pre-wrap;
      overflow-wrap: break-word;
      text-align: left !important;
      font-weight: 600;
    }
  }
`;
