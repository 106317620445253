import styled from "styled-components";

export const SketchContanier = styled.div`
  background-color: #e9ecef;
  flex: 1;
  color: #212121;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
  height: 3.05rem;
  text-align: center;
  border: 1px solid #d7d7d7;
`;
