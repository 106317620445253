import React from "react";

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Checkbox,
  useToast,
} from "@chakra-ui/react";

import { DocumentNode } from "graphql";

import { Flex, Heading, Spacer } from "@chakra-ui/layout";
import RippleButton from "../RippleButton";
import moment from "moment";
import { downloadAsJSON } from "../../utils/download_as_json";
import { useMutation } from "@apollo/client";
import { decryptObject, encryptObject } from "../../utils/crypt";
import { capitalizeFirstLetter } from "../../utils/string";

interface deleteDialogProps {
  messages: any;
  id: string | null;
  type: string;
  data: string | null;
  content: string | null;
  isOpen: boolean;
  onClose: () => void;
  DELETE_MUTATION: DocumentNode;
  LIST_QUERY: DocumentNode;
}

const DeleteDialog: React.FC<deleteDialogProps> = ({
  messages,
  type,
  isOpen,
  content,
  onClose,
  data,
  id,
  DELETE_MUTATION,
  LIST_QUERY,
}) => {
  const [DELETE_MUTATION_FN, { loading }] = useMutation(DELETE_MUTATION, {
    refetchQueries: [LIST_QUERY],
  });

  const DELETE_MUTATION_FN_STR = `delete${type[0].toUpperCase()}${type.slice(
    1,
    type.length
  )}`;

  const toast = useToast();

  const [is_checked, set_is_checked] = React.useState(false);
  const cancelRef = React.useRef(null);

  const triggerOnClose = () => {
    set_is_checked(false);
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={triggerOnClose}
      size="2xl"
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {capitalizeFirstLetter(messages[`permanently_delete_this_${type}`])}{" "}
            ?
          </AlertDialogHeader>

          <AlertDialogBody>
            <Flex direction="column">
              <Heading bg="gray.100" py="8px" px="4px" size="md" mb="24px">
                {content}
              </Heading>
              <Flex>
                <Checkbox
                  colorScheme="brand"
                  isChecked={is_checked}
                  onChange={() => set_is_checked(!is_checked)}
                >
                  {
                    messages[
                      "i_understand_that_deleting_is_permanent_and_cant_be_undone"
                    ]
                  }
                </Checkbox>
              </Flex>
            </Flex>
          </AlertDialogBody>

          <AlertDialogFooter>
            <RippleButton
              variant="unstyled"
              color="brand.500"
              onClick={() =>
                downloadAsJSON(
                  "BACKUP-" + content + moment().locale("en").format("DD-MM-YYYY") + ".json",
                  JSON.stringify(JSON.parse(data || "{}"), null, 4)
                )
              }
            >
              <Flex p="3px 32px">
                {messages[`download_${type}`].toUpperCase()}
              </Flex>
            </RippleButton>
            <Spacer />

            <RippleButton
              variant="unstyled"
              color="brand.500"
              onClick={triggerOnClose}
            >
              <Flex p="3px 32px">
                {capitalizeFirstLetter(messages["cancel"])}
              </Flex>
            </RippleButton>
            <RippleButton
              variant="unstyled"
              color="brand.500"
              disabled={!is_checked || loading}
              onClick={async () => {
                if (!id) {
                  return;
                }
                const { data } = await DELETE_MUTATION_FN({
                  variables: {
                    challenge: encryptObject({ id }),
                  },
                });
                if (!data) {
                  // console.log("something went wrong 01");
                }
                // console.log(data);

                const { msg } = decryptObject(data[DELETE_MUTATION_FN_STR]);
                if (!msg) {
                  // console.log("something went wrong 02");
                }

                if (msg === "created") {
                  triggerOnClose();

                  toast({
                    title: capitalizeFirstLetter(messages[`${type}_deleted`]),
                    description: messages[`weve_deleted_your_${type}_for_you`],
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              }}
            >
              <Flex p="3px 32px">{messages["delete"].toUpperCase()}</Flex>
            </RippleButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DeleteDialog;
