import { Box, Heading, Flex } from "@chakra-ui/react";
import { Text } from "@chakra-ui/layout";
import React from "react";

import CalendarComponent from "./Calendar";
import RippleButton from "../../Components/RippleButton";
import { useMutation } from "@apollo/client";
import {
  GET_BLOCKED_DATES_QUERY,
  SAVE_BLOCKED_DATE_MUTATION,
} from "../../Apollo/typedefs";
import { decryptObject, encryptObject } from "../../utils/crypt";
import NProgressComponent from "../../App/Layout/NProgress";
import DisplayErrorLangContainer from "../../Components/DisplayError/containers/lang";
import useLang from "../../hooks/useLang";
import { capitalizeFirstLetter } from "../../utils/string";

interface AvailabilityPageProps {
  blocked_dates: number[];
  messages: any;
}

export interface updateRequest {
  touched: boolean;
  blocked_dates: number[];
  new_blocked_dates: number[];
  unblocked_dates: number[];
}

const AvailabilityPage: React.FC<AvailabilityPageProps> = ({
  blocked_dates,
  messages,
}) => {
  const lang = useLang();
  const [saveBlockedDate, { loading, error }] = useMutation(
    SAVE_BLOCKED_DATE_MUTATION,
    {
      refetchQueries: [GET_BLOCKED_DATES_QUERY],
    }
  );

  const [disabled_dates, set_disabled_dates] = React.useState<updateRequest>({
    touched: false,
    blocked_dates,
    new_blocked_dates: [],
    unblocked_dates: [],
  });

  if (error) {
    return <DisplayErrorLangContainer error={error} />;
  }

  return (
    <Box mb="64px">
      <Heading size="xl" pt="23px" pl="32px">
        {capitalizeFirstLetter(messages["availability"])}
      </Heading>
      {loading && <NProgressComponent />}
      {!loading && (
        <>
          <Flex pt="32px" pl="64px">
            <Flex>
              <CalendarComponent
                locale={lang}
                disabled_dates={disabled_dates}
                set_disabled_dates={set_disabled_dates}
              />
            </Flex>
            <Flex direction="column" w="100%" p="16px">
              <Flex p="8px" alignItems="center">
                {!loading && disabled_dates.touched && (
                  <RippleButton
                    onClick={async () => {
                      const res = await saveBlockedDate({
                        variables: {
                          challenge: encryptObject({ disabled_dates }),
                        },
                      });
                      if (!res) {
                        alert("error 02848");
                      }

                      if (!res.data) {
                        alert("error 5464");
                      }

                      if (!res.data.saveBlockedDate) {
                        alert("error 4545445");
                      }
                      const { msg } = decryptObject(res.data.saveBlockedDate);
                      if (msg === "created") {
                        set_disabled_dates({
                          ...disabled_dates,
                          new_blocked_dates: [],
                        });
                      } else {
                        alert("error 748789456");
                      }
                    }}
                    variant="unstyled"
                    color="brand.500"
                  >
                    <Flex px="16px">
                      {capitalizeFirstLetter(messages["save_changes"])}
                    </Flex>
                  </RippleButton>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex w="100%" p="16px" pl="64px">
            <Flex p="8px" alignItems="center">
              <div className="indicator no-availabile-indicator"></div>
              <Text pl="32px">{capitalizeFirstLetter(messages["not_availabile"])}</Text>
            </Flex>
            <Flex p="8px" alignItems="center">
              <div className="indicator availabile-indicator"></div>
              <Text pl="32px">{capitalizeFirstLetter(messages["availabile"])}</Text>
            </Flex>
            <Flex p="8px" alignItems="center">
              <div className="indicator today-indicator"></div>
              <Text pl="32px">{capitalizeFirstLetter(messages["today"])}</Text>
            </Flex>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default AvailabilityPage;
