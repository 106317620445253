import { getAttrOfItemBykey, updateObject } from "../../../../../utils/object";
import { FilterType } from "../../../types";

export const updateFilterSearchType = (
  value: any,
  selected_filter_key: string,
  local_filters: FilterType[] | null,
  set_local_filters: React.Dispatch<React.SetStateAction<FilterType[] | null>>
) => {
  if (value) {
    // console.log(" OLD filters is => ", filters);
    // console.log("input is => ", value);

    const newUpdate: FilterType = {
      key: selected_filter_key,
      search_type: value,
      name: getAttrOfItemBykey(selected_filter_key, local_filters, "name"),
      value: getAttrOfItemBykey(selected_filter_key, local_filters, "value"),
      type: getAttrOfItemBykey(selected_filter_key, local_filters, "type"),
    };
    // console.log("obj to update is => ", newUpdate);

    const newUpdatedObj = updateObject(newUpdate, local_filters);
    // console.log("new obj arr =>", newUpdatedObj);

    // console.log(
    //   "newUpdatedObj is => ",
    //   newUpdatedObj
    // );

    set_local_filters([...newUpdatedObj]);
  }
};

export const updateFilterValue = (
  value: any,
  selected_filter_key: string,
  local_filters: FilterType[] | null,
  set_local_filters: React.Dispatch<React.SetStateAction<FilterType[] | null>>
) => {
  if (value !== null) {
    // console.log(" OLD filters is => ", filters);
    // console.log("input is => ", value);

    const newUpdate: FilterType = {
      key: selected_filter_key,
      name: getAttrOfItemBykey(selected_filter_key, local_filters, "name"),
      search_type: getAttrOfItemBykey(
        selected_filter_key,
        local_filters,
        "search_type"
      ),
      value: value,
      type: getAttrOfItemBykey(selected_filter_key, local_filters, "type"),
    };
    // console.log("obj to update is => ", newUpdate);

    const newUpdatedObj = updateObject(newUpdate, local_filters);
    // console.log("new obj arr =>", newUpdatedObj);

    // console.log(
    //   "newUpdatedObj is => ",
    //   newUpdatedObj
    // );

    set_local_filters([...newUpdatedObj]);
  }
};
