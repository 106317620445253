import React from "react";
import { Box, Flex, Heading } from "@chakra-ui/layout";

import ReservationsTableComponent from "./ReservationsTable";
import { capitalizeFirstLetter } from "../../utils/string";
import { useLocation } from "react-router";
import AdvancedReservationsTableComponent from "./advenced/ReservationsTable";

interface ReservationsPageProps {
  messages: any;
}
const ReservationsPage: React.FC<ReservationsPageProps> = ({ messages }) => {
  const location = useLocation();

  const isAdvanced = location && location.pathname === "/reservations/advanced";

  // console.log(location);

  return (
    <Box mb="64px">
      <Heading size="xl" pt="23px" pl="32px">
        {capitalizeFirstLetter(messages["reservations"])}
      </Heading>

      <Flex pt="32px" px="32px">
        {!isAdvanced && <ReservationsTableComponent messages={messages} />}
        {isAdvanced && (
          <AdvancedReservationsTableComponent messages={messages} />
        )}
      </Flex>
    </Box>
  );
};

export default ReservationsPage;
