import { Button } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { Flex, Text } from "@chakra-ui/layout";
import React from "react";
import {
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdOutlineFirstPage,
  MdOutlineLastPage,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import { decryptObject, encryptObject } from "../../utils/crypt";
import { capitalizeFirstLetter } from "../../utils/string";
import SelectComponent from "../Select";
import { PaginationContanier } from "./styles";

interface PaginationProps {
  pages: number;
  messages: any;
  entity: string;
}

const PaginationComponent: React.FC<PaginationProps> = ({
  pages,
  messages,
  entity,
}) => {
  const navigateTo = useNavigate();

  let challenge: any;
  const location = useLocation();
  if (location.search) {
    const params = new URLSearchParams(location.search);
    if (params.has("q")) {
      challenge = decryptObject(params.get("q"));
    }
  }

  if (!challenge) {
    challenge = {
      page: 1,
      limit: 10,
    };
  }

  const { page, limit } = challenge;

  return (
    <PaginationContanier>
      <Flex alignItems="center">
        <Text fontSize="xs" color="gray.600">
          {capitalizeFirstLetter(messages["rows_per_page"])} :
        </Text>
        <SelectComponent entity={entity} defaultLimit={limit} />
        <Text ml="24px" fontSize="xs" color="gray.600">
          {page} / {pages}
        </Text>
        <Flex ml="24px">
          <Button
            disabled={page === 1}
            onClick={() =>
              navigateTo(
                `/${entity}?q=${encryptObject({
                  page: 1,
                  limit,
                })}`
              )
            }
            p="0"
            boxShadow="0"
            _focus={{ boxShadow: "0" }}
            variant="ghost"
            rounded="full"
          >
            <Icon as={MdOutlineFirstPage} w="6" h="6" p="8pw" />
          </Button>
          <Button
            disabled={page === 1}
            onClick={() =>
              navigateTo(
                `/${entity}?q=${encryptObject({
                  page: page - 1,
                  limit,
                })}`
              )
            }
            p="0"
            boxShadow="0"
            _focus={{ boxShadow: "0" }}
            variant="ghost"
            rounded="full"
            ml="4px"
          >
            <Icon as={MdOutlineChevronLeft} w="6" h="6" p="8pw" />
          </Button>
          <Button
            onClick={() =>
              navigateTo(
                `/${entity}?q=${encryptObject({
                  page: page + 1,
                  limit,
                })}`
              )
            }
            disabled={page === pages}
            p="0"
            boxShadow="0"
            _focus={{ boxShadow: "0" }}
            variant="ghost"
            rounded="full"
            ml="4px"
          >
            <Icon as={MdOutlineChevronRight} w="6" h="6" p="8pw" />
          </Button>
          <Button
            disabled={page === pages}
            onClick={() =>
              navigateTo(
                `/${entity}?q=${encryptObject({
                  page: pages,
                  limit,
                })}`
              )
            }
            p="0"
            boxShadow="0"
            _focus={{ boxShadow: "0" }}
            variant="ghost"
            rounded="full"
            ml="4px"
          >
            <Icon as={MdOutlineLastPage} w="6" h="6" p="8pw" />
          </Button>
        </Flex>
      </Flex>
    </PaginationContanier>
  );
};

export default PaginationComponent;
