import { Flex, Box } from "@chakra-ui/react";
import React from "react";
import { capitalizeFirstLetter } from "../../../utils/string";
import { FilterType } from "../types";
import DropdownMenuItem from "./Item";

interface FilterDropdownMenuProps {
  dropdownTop: string;
  filters: FilterType[] | null;
  set_is_filter_menu_closed: React.Dispatch<React.SetStateAction<boolean>>;
  set_is_filter_menu_input_closed: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  set_selected_filter_key: React.Dispatch<React.SetStateAction<string | null>>;
}

const FilterDropdownMenu: React.FC<FilterDropdownMenuProps> = ({
  dropdownTop,
  filters,
  set_is_filter_menu_closed,
  set_is_filter_menu_input_closed,
  set_selected_filter_key,
}) => {
  return (
    <Flex
      outline="none"
      minW="128px"
      borderRadius="4px"
      boxShadow="0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%)"
      maxH="240px"
      top={dropdownTop}
      position="fixed"
      bg="white"
      zIndex="200"
    >
      {filters && (
        <Flex overflowY="scroll" w="100%" zIndex="201" direction="column">
          {filters
            .filter((el) => el.value === null)
            .map((el, index) => (
              <Flex
                key={index}
                flex="1"
                onClick={() => {
                  set_is_filter_menu_closed(true);
                  set_is_filter_menu_input_closed(false);
                  set_selected_filter_key(el.key + "");
                }}
              >
                <DropdownMenuItem>
                  {capitalizeFirstLetter(el.name)}
                </DropdownMenuItem>
              </Flex>
            ))}
        </Flex>
      )}
      <Box
        onClick={() => set_is_filter_menu_closed(true)}
        onWheel={() => set_is_filter_menu_closed(true)}
        zIndex="200"
        top="0"
        right="0"
        bottom="0"
        left="0"
        position="fixed"
        w="100vw"
        h="100vh"
      ></Box>
    </Flex>
  );
};

export default FilterDropdownMenu;
