import React from "react";
import { config } from "../config";
import { LSK } from "../lang/localStorageKey";

const useMessagesLocalStorage = (path: string) => {
  const [messages, set_messages] = React.useState("");

  const lang = localStorage.getItem(LSK) || config.DEFAULT_LANG;

  React.useEffect(() => {
    async function fetchlang() {
      const response = await fetch(`${path}${lang}.json`);
      // waits until the request completes...
      const _msgs = await response.json();

      await set_messages(JSON.stringify(_msgs));
    }
    fetchlang();
  }, [lang, path]);

  return messages;
};

export default useMessagesLocalStorage;
