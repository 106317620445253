import { Icon } from "@chakra-ui/react";
import { Flex, Text } from "@chakra-ui/layout";
import React from "react";
import { MdArrowDropDown } from "react-icons/md";
import RippleButton from "../RippleButton";
import { encryptObject } from "../../utils/crypt";
import { useNavigate } from "react-router";

interface SelectProps {
  defaultLimit: number;
  entity: string
}

const list_rows_per_page = [ 10, 30, 50];

const SelectComponent: React.FC<SelectProps> = ({ defaultLimit, entity }) => {
  const navigateTo = useNavigate();
  const [toggle, set_toggle] = React.useState(false);
  const [current_rows_per_page, set_current_rows_per_page] =
    React.useState(defaultLimit);
  return (
    <Flex position="relative">
      <RippleButton
      variant="unstyled"
        onClick={() => set_toggle(true)}
        onBlur={() => {
          setTimeout(() => {
            set_toggle(false);
          }, 300);
        }}
        color="gray"
        ml="16px"
      >
        <Flex fontSize="xs" pl="4px" alignItems="center">
          <Text pr="16px">{current_rows_per_page}</Text>{" "}
          <Icon color="gray" w="6" h="6" as={MdArrowDropDown} />
        </Flex>
      </RippleButton>
      {toggle && (
        <>
          <Listbox>
            {list_rows_per_page.map((limit) => (
              <ListboxItem
                active={limit === current_rows_per_page}
                onClick={() => {
                  navigateTo(
                    `/${entity}?q=${encryptObject({
                      page: 1,
                      limit,
                    })}`
                  );
                  set_current_rows_per_page(limit);
                  set_toggle(false);
                }}
              >
                {limit}
              </ListboxItem>
            ))}
          </Listbox>
        </>
      )}
    </Flex>
  );
};

export default SelectComponent;

interface ListboxProps {
  onClick?: Function;
}

const Listbox: React.FC<ListboxProps> = ({ children }) => (
  <>
    <Flex
      zIndex="201"
      bottom="0"
      right="0"
      position="absolute"
      py="8px"
      bg="white"
      boxShadow="0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4)"
      direction="column"
    >
      {children}
    </Flex>
  </>
);

interface ListboxItemProps {
  onClick?: Function;
  active?: boolean;
}

const ListboxItem: React.FC<ListboxItemProps> = ({
  children,
  onClick,
  active,
}) => (
  <Flex
    bg={active ? "gray.100" : "white"}
    onClick={() => onClick && onClick()}
    px="24px"
    py="4px"
    cursor="pointer"
    fontSize="md"
    _hover={{ bg: "gray.200" }}
  >
    {children}
  </Flex>
);
