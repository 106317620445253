import moment from "moment";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
} from "@chakra-ui/react";
import { capitalizeFirstLetter } from "../utils/string";

const duration_in_minutes = 15; // minutes

export const Shield: any = ({ secr, messages }: any) => {
  const sec = secr.substring(3, secr.length - 3);
  const pure = atob(sec);

  var present = moment().locale("en");
  var last_try = moment(new Date(pure)).locale("en");

  const diff = present.diff(last_try, "minutes");

  if (diff >= duration_in_minutes) {
    localStorage.removeItem("ga__54684565");
    localStorage.removeItem("ga_465489");
    window.location.reload();
  } else {
    return (
      <Flex alignItems="center" justifyContent="center" height="100vh">
        <Box w="md" h="md">
          <Flex>
            <Alert status="warning">
              <AlertIcon />
              <Box flex="1">
                <AlertTitle>
                  {capitalizeFirstLetter(messages["warning"])}
                </AlertTitle>
                <AlertDescription display="block">
                  {capitalizeFirstLetter(
                    messages[
                      "youve_reached_the_maximum_logon_attempts_please_retry_after"
                    ]
                  )}{" "}
                  <b id="down_counter_login">{duration_in_minutes - +diff}</b>{" "}
                  {messages["minutes"]}.
                </AlertDescription>
              </Box>
            </Alert>
          </Flex>
        </Box>
      </Flex>
    );
  }
};
