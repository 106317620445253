import * as React from "react";
import { useColorMode, useColorModeValue, Icon } from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";

export const ColorModeSwitcher: React.FC = (props) => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue("dark", "light");
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  return (
    <Icon
      fontSize="lg"
      onClick={toggleColorMode}
      as={SwitchIcon}
      title={`Switch to ${text} mode`}
      cursor="pointer"
      color="gray.900"
      _hover={{ color: "gray.700" }}
      {...props}
    />
  );
};
