import React from "react";

import moment from "moment";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/da";
import "moment/locale/hu";
import "moment/locale/nn";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/fi";
import "moment/locale/sv";

import { MdDelete, MdDownload, MdEdit } from "react-icons/md";
import { Flex } from "@chakra-ui/layout";
import { Icon, Button } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "../../Components/ResponsiveTable";

import { useQuery } from "@apollo/client";
import { decryptObject, encryptObject } from "../../utils/crypt";
import { DELETE_PRODUCT, PRODUCTS_QUERY } from "../../Apollo/typedefs";
import NProgressComponent from "../../App/Layout/NProgress";
import PaginationComponent from "../../Components/Pagination";
import { useLocation, useNavigate } from "react-router";
import DeleteDialog from "../../Components/DeleteDialog";
import { downloadAsJSON } from "../../utils/download_as_json";
import NoContentIllustration from "../../Components/NoContentIllustration";
import FormComponent, { productRequestType } from "./form";
import FilterComponent from "../../Components/Filter";
import DisplayErrorLangContainer from "../../Components/DisplayError/containers/lang";
import useLang from "../../hooks/useLang";
import { FilterType } from "../../Components/Filter/types";

interface ProductsTableProps {
  messages: any;
}

let default_challenge = encryptObject({
  page: 1,
  limit: 10,
});

const declarate_filters = [
  {
    key: "name",
    name: "name",
    search_type: "contains",
    type: "string",
  },
  {
    key: "price",
    name: "price",
    search_type: "equal",
    type: "number",
  },
];

const ProductsTableComponent: React.FC<ProductsTableProps> = ({ messages }) => {
  const lang = useLang();
  moment.locale(lang === "no" ? "nn" : lang);

  const onClose = () => setCurrentProductToDelete(null);
  const navigateTo = useNavigate();

  const [currentProductToDelete, setCurrentProductToDelete] = React.useState<{
    id: string;
    name: string;
    data: string;
  } | null>(null);

  const [product_data_to_edit, set_product_data_to_edit] =
    React.useState<productRequestType | null>(null);

  let defaultSearch: any = [];

  declarate_filters.forEach((filter) => {
    const _single_filter: FilterType = {
      key: filter.key,
      name: messages[filter.key],
      value: null,
      search_type: filter.search_type,
      type: filter.type,
    };
    defaultSearch.push(_single_filter);
  });

  let challenge: string | null = null;
  const location = useLocation();

  if (location.search) {
    const params = new URLSearchParams(location.search);
    if (params.has("q")) {
      // challenge = decryptObject(params.get("q"));
      challenge = params.get("q");
      const raw_challenge = decryptObject(params.get("q"));
      // console.log("raw_challenge=>", raw_challenge);

      if (raw_challenge && raw_challenge.filter) {
        // console.log("raw_challenge.filter=>", raw_challenge.filter);

        defaultSearch = [...raw_challenge.filter];
      }
    }
  }

  const on_filter = (local_filters: any, clear: boolean = false) => {
    // console.log("local_filters=>", local_filters);

    let selected_challenge = challenge
      ? { ...decryptObject(challenge) }
      : { ...decryptObject(default_challenge) };
    // console.log("selected_challenge=>", selected_challenge);

    if (clear) {
      delete selected_challenge.filters;
    }

    let tmp = {
      ...selected_challenge,
    };

    tmp.filter = [...local_filters];

    const final_challenge = {
      ...tmp,
    };

    // console.log("final_challenge=>", final_challenge);

    navigateTo(`/products?q=${encryptObject({ ...final_challenge })}`);
  };

  const { data, error, loading } = useQuery(PRODUCTS_QUERY, {
    variables: { challenge: challenge ? challenge : default_challenge },
  });

  const [filters, set_filter] = React.useState<FilterType[] | null>(
    defaultSearch
  );

  React.useEffect(() => {
    if (data && data.products && decryptObject(data.products).filter) {
      set_filter(decryptObject(data.products).filter);
    }
  }, [data]);

  if (loading) {
    return <NProgressComponent />;
  }

  if (error) {
    return <DisplayErrorLangContainer error={error} />;
  }

  if (data && data.products) {
    const products = decryptObject(data.products).products;
    const pages = decryptObject(data.products).pages;
    // console.log("data=>", decryptObject(data.products));

    return (
      <Flex w="100%" direction="column">
        {/* filters: <pre>{JSON.stringify(filters, null, 2)}</pre> */}
        <FilterComponent
          filters={filters}
          on_filter={on_filter}
          defaultSearch={defaultSearch}
          row_height={48}
          dropdownTop={`306px`}
          filterModalInputTop={`15vh`}
          filterModalInputLeft={`15vw`}
        />
        {(!products || !pages) && (
          <NoContentIllustration hint={messages["try_to_clear_filter"]} />
        )}
        {products && pages > 0 && (
          <>
            <Table w="100%" variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th fontSize="16px">{messages["name"]}</Th>
                  <Th fontSize="16px">{messages["price"]} (€)</Th>
                  <Th fontSize="16px">{messages["createdAt"]}</Th>
                  <Th fontSize="16px">{messages["action"]}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {products.map((productItem: any, i: any) => {
                  return (
                    <Tr key={i}>
                      <Td>{JSON.parse(productItem.name)[lang]}</Td>
                      <Td>{productItem.price}€</Td>
                      <Td>
                        {moment(productItem.createdAt).locale("en").format(
                          "dddd DD MMM YYYY - HH:mm"
                        )}
                      </Td>
                      <Td>
                        <Button
                          p="0"
                          boxShadow="0"
                          _focus={{ boxShadow: "0" }}
                          variant="ghost"
                          rounded="full"
                          onClick={() => set_product_data_to_edit(productItem)}
                        >
                          <Icon as={MdEdit} w="6" h="6" p="8pw" />
                        </Button>
                        <Button
                          p="0"
                          boxShadow="0"
                          _focus={{ boxShadow: "0" }}
                          variant="ghost"
                          rounded="full"
                          onClick={() =>
                            downloadAsJSON(
                              "BACKUP-" +
                                productItem.name +
                                moment().locale("en").format("DD-MM-YYYY") +
                                ".json",
                              JSON.stringify({ productItem }, null, 4)
                            )
                          }
                        >
                          <Icon as={MdDownload} w="6" h="6" p="8pw" />
                        </Button>
                        <Button
                          p="0"
                          boxShadow="0"
                          _focus={{ boxShadow: "0" }}
                          variant="ghost"
                          rounded="full"
                          onClick={() => {
                            if (
                              productItem &&
                              productItem.id &&
                              productItem.name
                            ) {
                              setCurrentProductToDelete({
                                id: productItem.id,
                                name: productItem.name,
                                data: JSON.stringify(productItem),
                              });
                            }
                          }}
                        >
                          <Icon as={MdDelete} w="6" h="6" p="8pw" />
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <PaginationComponent
              entity="products"
              messages={messages}
              pages={pages}
            />
            <DeleteDialog
              messages={messages}
              id={currentProductToDelete && currentProductToDelete.id}
              data={currentProductToDelete && currentProductToDelete.data}
              content={
                currentProductToDelete &&
                JSON.parse(currentProductToDelete.name)[lang]
              }
              type="product"
              onClose={onClose}
              isOpen={currentProductToDelete !== null}
              DELETE_MUTATION={DELETE_PRODUCT}
              LIST_QUERY={PRODUCTS_QUERY}
            />
            <FormComponent
              messages={messages}
              data={product_data_to_edit}
              type="update"
              cleanup={() => set_product_data_to_edit(null)}
            />
          </>
        )}
      </Flex>
    );
  } else {
    return <div>err</div>;
  }
};

export default ProductsTableComponent;
