import {
  MdDoNotDisturb,
  MdHomeFilled,
  MdOutlineDocumentScanner,
  MdPriceChange,
  MdShoppingBasket,
} from "react-icons/md";
import { capitalizeFirstLetter } from "../../../utils/string";

export const getMenu = (messages: any): any => [
  {
    name: capitalizeFirstLetter(messages["home"]),
    icon: MdHomeFilled,
    href: "/",
  },
  {
    name: capitalizeFirstLetter(messages["reservations"]),
    icon: MdOutlineDocumentScanner,
    href: "/reservations",
  },
  {
    name: capitalizeFirstLetter(messages["prices"]),
    icon: MdPriceChange,
    href: "/prices",
  },
  {
    name: capitalizeFirstLetter(messages["availability"]),
    icon: MdDoNotDisturb,
    href: "/availability",
  },
  {
    name: capitalizeFirstLetter(messages["products"]),
    icon: MdShoppingBasket,
    href: "/products",
  },
];
