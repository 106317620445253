import { useQuery } from "@apollo/client";
import React from "react";
import { PRICE_BY_ID_QUERY } from "../../Apollo/typedefs";
import useMessagesLocalStorage from "../../hooks/useMessagesLocalStorage";
import ModalPreview from "../../pages/Prices/ModalPreview";
import { decryptObject } from "../../utils/crypt";

interface PriceContainerByIDProps {
  challenge: any;
}

const PriceContainerByID: React.FC<PriceContainerByIDProps> = ({
  challenge,
}) => {
  const { data, error, loading } = useQuery(PRICE_BY_ID_QUERY, {
    variables: { challenge },
  });

  const messages = useMessagesLocalStorage(`/lang/pages/prices/`);

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>error</span>;
  }

  if (data && data.priceById) {
    const { price } = decryptObject(data.priceById);

    if (price) {
      if (messages === "") {
        return <div>loarding...</div>;
      } else {
        return (
          <ModalPreview priceItem={price[0]} messages={JSON.parse(messages)} />
        );
      }
    } else {
      return <span>error 1</span>;
    }
  } else {
    return <span>error 0</span>;
  }
};

export default PriceContainerByID;
