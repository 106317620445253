import React from "react";
import moment from "moment";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/da";
import "moment/locale/hu";
import "moment/locale/nn";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/fi";
import "moment/locale/sv";

import {
  MdCancel,
  MdEdit,
  MdManageSearch,
  MdNotInterested,
  MdPrint,
} from "react-icons/md";
import { Badge, Flex, Link } from "@chakra-ui/layout";
import { Icon, Button } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "../../Components/ResponsiveTable";

import { useQuery } from "@apollo/client";
import { decryptObject, encryptObject } from "../../utils/crypt";
import { RESERVATIONS_QUERY } from "../../Apollo/typedefs";
import NProgressComponent from "../../App/Layout/NProgress";
import PaginationComponent from "../../Components/Pagination";
import { useLocation, useNavigate } from "react-router";
import NoContentIllustration from "../../Components/NoContentIllustration";
import FilterComponent from "../../Components/Filter";
import GetColorForStatus from "./utils/GetColorForStatus";
import DisplayErrorLangContainer from "../../Components/DisplayError/containers/lang";
import { FilterType } from "../../Components/Filter/types";
import useLang from "../../hooks/useLang";
import { capitalizeFirstLetter } from "../../utils/string";
import RippleButton from "../../Components/RippleButton";
import { config } from "../../config";

let default_challenge = encryptObject({
  page: 1,
  limit: 10,
});

const declarate_filters = [
  {
    key: "start_date",
    name: "start_date",
    search_type: "default",
    type: "date",
  },
  {
    key: "end_date",
    name: "end_date",
    search_type: "default",
    type: "date",
  },
  {
    key: "num_ticket",
    name: "ticket",
    search_type: "equal",
    type: "string",
  },
  {
    key: "full_name",
    name: "full_name",
    search_type: "contains",
    type: "string",
  },
  {
    key: "email",
    name: "email",
    search_type: "contains",
    type: "string",
  },
  {
    key: "full_phone_number",
    name: "full_phone_number",
    search_type: "contains",
    type: "string",
  },
  {
    key: "createdAt",
    name: "createdAt",
    search_type: "equal",
    type: "date",
  },
  {
    key: "updatedAt",
    name: "updatedAt",
    search_type: "equal",
    type: "date",
  },

  {
    key: "total_price",
    name: "total_price",
    search_type: "equal",
    type: "number",
  },
  {
    key: "status",
    name: "status",
    search_type: "equal",
    type: "select",
  },
];

interface ReservationsTableComponentProps {
  messages: any;
}

const ReservationsTableComponent: React.FC<ReservationsTableComponentProps> = ({
  messages,
}) => {
  const lang = useLang();
  moment.locale(lang === "no" ? "nn" : lang);

  const navigateTo = useNavigate();

  let defaultSearch: any = [];
  let cpy_defaultSearch: any = [];

  declarate_filters.forEach((filter) => {
    const _single_filter: FilterType = {
      key: filter.key,
      name: messages[filter.name ? filter.name : filter.key],
      value: null,
      search_type: filter.search_type,
      type: filter.type,
    };
    defaultSearch.push(_single_filter);
    cpy_defaultSearch.push(_single_filter);
  });

  let challenge: string | null = null;
  const location = useLocation();

  if (location.search) {
    const params = new URLSearchParams(location.search);
    if (params.has("q")) {
      // challenge = decryptObject(params.get("q"));
      challenge = params.get("q");
      const raw_challenge = decryptObject(params.get("q"));
      // console.log("raw_challenge=>", raw_challenge);

      if (raw_challenge && raw_challenge.filter) {
        // console.log("raw_challenge.filter=>", raw_challenge.filter);

        defaultSearch = [...raw_challenge.filter];
      }
    }
  }

  const on_filter = (local_filters: any, clear: boolean = false) => {
    // console.log("is ? clear => ", clear);

    // console.log("local_filters=>", local_filters);

    let selected_challenge = challenge
      ? { ...decryptObject(challenge) }
      : { ...decryptObject(default_challenge) };
    // console.log("selected_challenge=>", selected_challenge);

    if (clear) {
      delete selected_challenge.filters;
    }

    let tmp = {
      ...selected_challenge,
    };

    tmp.filter = [...local_filters];

    // console.log("tmp=>", tmp);

    const final_challenge = {
      ...tmp,
    };

    // console.log("final_challenge=>", final_challenge);

    navigateTo(`/reservations?q=${encryptObject({ ...final_challenge })}`);
  };

  const { data, error, loading } = useQuery(RESERVATIONS_QUERY, {
    variables: { challenge: challenge ? challenge : default_challenge },
  });

  const [filters, set_filter] = React.useState<FilterType[] | null>(
    defaultSearch
  );

  React.useEffect(() => {
    return () => {
      declarate_filters.forEach((filter) => {
        const _single_filter: FilterType = {
          key: filter.key,
          name: messages[filter.name ? filter.name : filter.key],
          value: null,
          search_type: filter.search_type,
          type: filter.type,
        };
        defaultSearch.push(_single_filter);
      });
      set_filter([...cpy_defaultSearch]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if (data && data.reservations && decryptObject(data.reservations).filter) {
      set_filter(decryptObject(data.reservations).filter);
    }
  }, [data]);

  if (loading) {
    return <NProgressComponent />;
  }

  if (error) {
    return <DisplayErrorLangContainer error={error} />;
  }

  if (data && data.reservations) {
    const reservations = decryptObject(data.reservations).reservations;
    const pages = decryptObject(data.reservations).pages;
    // console.log("data=>", decryptObject(data.reservations));

    return (
      <Flex w="100%" direction="column">
        {/* filters: <pre>{JSON.stringify(filters, null, 2)}</pre> */}
        <Flex>
          <RippleButton
            onClick={() => navigateTo("advanced")}
            pr="16px"
            variant="unstyled"
            rounded="0"
            color="brand.500"
          >
            <Flex pr="16px">
              <Icon as={MdManageSearch} w="6" h="6" mr="4px" />{" "}
              <span>{messages["advanced_search"].toUpperCase()}</span>
            </Flex>
          </RippleButton>
        </Flex>
        <FilterComponent
          filters={filters}
          on_filter={on_filter}
          defaultSearch={defaultSearch}
          row_height={48}
          dropdownTop={`258px`}
          filterModalInputTop={"15vh"}
          filterModalInputLeft={"15vw"}
        />
        {(!reservations || !pages) && (
          <NoContentIllustration
            hint={capitalizeFirstLetter(messages["try_to_clear_filter"])}
          />
        )}
        {reservations && pages > 0 && (
          <>
            <Table w="100%" variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th fontSize="16px">
                    <Flex alignItems="center" justifyContent="center">
                      {messages["ticket"]}
                    </Flex>
                  </Th>
                  <Th fontSize="16px">
                    <Flex alignItems="center" justifyContent="center">
                      {messages["full_name"]}
                    </Flex>
                  </Th>
                  <Th fontSize="16px">
                    <Flex alignItems="center" justifyContent="center">
                      {messages["email"]}
                    </Flex>
                  </Th>
                  <Th fontSize="16px">
                    <Flex alignItems="center" justifyContent="center">
                      {messages["full_phone_number"]}
                    </Flex>
                  </Th>
                  <Th fontSize="16px">
                    <Flex alignItems="center" justifyContent="center">
                      {messages["createdAt"]}
                    </Flex>
                  </Th>
                  <Th fontSize="16px">
                    <Flex alignItems="center" justifyContent="center">
                      {messages["updatedAt"]}
                    </Flex>
                  </Th>
                  <Th fontSize="16px">
                    <Flex alignItems="center" justifyContent="center">
                      {messages["start_date"]}
                    </Flex>
                  </Th>
                  <Th fontSize="16px">
                    <Flex alignItems="center" justifyContent="center">
                      {messages["end_date"]}
                    </Flex>
                  </Th>
                  <Th fontSize="16px">
                    <Flex alignItems="center" justifyContent="center">
                      {messages["total_price"] + "(€)"}
                    </Flex>
                  </Th>
                  <Th fontSize="16px">
                    <Flex alignItems="center" justifyContent="center">
                      {messages["status"]}
                    </Flex>
                  </Th>
                  <Th fontSize="16px">
                    <Flex alignItems="center" justifyContent="center">
                      {messages["action"]}
                    </Flex>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {reservations.map((reservationItem: any, i: any) => (
                  <Tr key={i}>
                    <Td>
                      <Flex alignItems="center" justifyContent="center">
                        {reservationItem.num_ticket}
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" justifyContent="center">
                        {reservationItem.full_name}
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" justifyContent="center">
                        {reservationItem.email}
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" justifyContent="center">
                        {reservationItem.full_phone_number}
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" justifyContent="center">
                        {moment(reservationItem.createdAt).locale("en").format(
                          "dddd DD MMM YYYY - HH:mm"
                        )}
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" justifyContent="center">
                        {reservationItem.updatedAt !==
                        reservationItem.createdAt ? (
                          moment(reservationItem.updatedAt).locale("en").format(
                            "dddd DD MMM YYYY - HH:mm"
                          )
                        ) : (
                          <Flex alignItems="center" justifyContent="center">
                            <Icon
                              color="gray"
                              as={MdNotInterested}
                              w="6"
                              h="6"
                              p="8pw"
                            />
                          </Flex>
                        )}
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" justifyContent="center">
                        {moment(
                          reservationItem.start_date_int,
                          "YYYYMMDD"
                        ).locale("en").format("dddd DD MMM YYYY")}{" "}
                        {reservationItem.start_time}
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" justifyContent="center">
                        {moment(
                          reservationItem.end_date_int,
                          "YYYYMMDD"
                        ).locale("en").format("dddd DD MMM YYYY")}{" "}
                        {reservationItem.end_time}
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" justifyContent="center">
                        <Badge px="15px" variant="solid" colorScheme="green">
                          {reservationItem.total_price} €
                        </Badge>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" justifyContent="center">
                        <GetColorForStatus
                          status={reservationItem.status}
                          name={messages[reservationItem.status]}
                        />
                      </Flex>
                    </Td>
                    <Td>
                      <Flex alignItems="center" justifyContent="center">
                        <Button
                          as={Link}
                          p="0"
                          boxShadow="0"
                          _focus={{ boxShadow: "0" }}
                          variant="ghost"
                          rounded="full"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${config.BEP}/a/${encryptObject({
                            action: "print",
                            id: reservationItem.id,
                          })}?lang=${config.DEFAULT_LANG}&manageR=true`}
                        >
                          <Icon as={MdPrint} w="6" h="6" p="8pw" />
                        </Button>
                        <Button
                          as={Link}
                          p="0"
                          boxShadow="0"
                          _focus={{ boxShadow: "0" }}
                          variant="ghost"
                          rounded="full"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${config.BEP}/a/${encryptObject({
                            action: "edit",
                            id: reservationItem.id,
                          })}?lang=${config.DEFAULT_LANG}&manageR=true`}
                        >
                          <Icon as={MdEdit} w="6" h="6" p="8pw" />
                        </Button>

                        <Button
                          as={Link}
                          p="0"
                          boxShadow="0"
                          _focus={{ boxShadow: "0" }}
                          variant="ghost"
                          rounded="full"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${config.BEP}/a/${encryptObject({
                            action: "cancel",
                            id: reservationItem.id,
                          })}?lang=${config.DEFAULT_LANG}&manageR=true`}
                        >
                          <Icon as={MdCancel} w="6" h="6" p="8pw" />
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <PaginationComponent
              entity="reservations"
              messages={messages}
              pages={pages}
            />
          </>
        )}
      </Flex>
    );
  } else {
    return <div>err</div>;
  }
};

export default ReservationsTableComponent;
