import ProductsPage from "..";
import useMessagesLocalStorage from "../../../hooks/useMessagesLocalStorage";

const ProductsPageLangContainer = (props: any) => {
  const messages = useMessagesLocalStorage(`/lang/pages/products/`);

  if (messages === "") {
    return <div>loarding...</div>;
  } else {
    return <ProductsPage {...props} messages={JSON.parse(messages)} />;
  }
};

export default ProductsPageLangContainer;
