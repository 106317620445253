export const lang_list = [
  {
    label: "Nederlands",
    href: "/?lang=nl",
    code: "nl",
  },
  {
    label: "Français",
    href: "/?lang=fr",
    code: "fr",
  },
  {
    label: "Deutsch",
    href: "/?lang=de",
    code: "de",
  },
  {
    label: "English",
    href: "/?lang=en",
    code: "en",
  },
  {
    label: "Español",
    href: "/?lang=es",
    code: "es",
  },
  {
    label: "Italiano",
    href: "/?lang=it",
    code: "it",
  },
  {
    label: "Dansk",
    href: "/?lang=da",
    code: "da",
  },
  {
    label: "Magyar",
    href: "/?lang=hu",
    code: "hu",
  },
  {
    label: "Norsk",
    href: "/?lang=no",
    code: "no",
  },
  {
    label: "Polski",
    href: "/?lang=pl",
    code: "pl",
  },
  {
    label: "Português",
    href: "/?lang=pt",
    code: "pt",
  },
  {
    label: "Suomi",
    href: "/?lang=fi",
    code: "fi",
  },
  {
    label: "Svenska",
    href: "/?lang=sv",
    code: "sv",
  },
];
