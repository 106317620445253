import { getAttrOfItemBykey, getValueOfItemBykey } from "../../../utils/object";
import { FilterType } from "../types";

export const getDisabledState = (
  selected_filter_key: string,
  local_filters: FilterType[] | null,
  filters: FilterType[] | null = null
) => {
  if (selected_filter_key) {
    const __check_type = getAttrOfItemBykey(
      selected_filter_key,
      local_filters,
      "type"
    );

    const __check_value = getAttrOfItemBykey(
      selected_filter_key,
      local_filters,
      "value"
    );

    const __check_search_type = getAttrOfItemBykey(
      selected_filter_key,
      local_filters,
      "search_type"
    );

    switch (__check_type) {
      case "string":
      case "number":
      case "date":
        if (
          __check_value !== null &&
          (__check_search_type === "in" || __check_search_type === "out")
        ) {
          if (__check_type === "date" && __check_value.length <= 8) {
            return true;
          }
          // console.log("typeof __check_value", typeof __check_value);

          // console.log(
          //   "JSON.parse(__check_value)[0]",
          //   JSON.parse(__check_value)[0]
          // );
          // console.log(
          //   "JSON.parse(__check_value)[1]",
          //   JSON.parse(__check_value)[1]
          // );

          if (
            JSON.parse(__check_value)[0] !== 0 &&
            JSON.parse(__check_value)[0] !== "" &&
            JSON.parse(__check_value)[1] !== 0 &&
            JSON.parse(__check_value)[1] !== ""
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          if (typeof __check_value === "string") {
            if (__check_value.length > 0) return false;
          } else {
            return true;
          }
        }

        break;

      case "select":
        // console.log("element type = ", __check_type);
        // console.log("element value = ", __check_value);

        const current_URL_value = getValueOfItemBykey(
          selected_filter_key,
          filters
        );

        // console.log("element search_type = ", __check_search_type);

        const current_URL_search_type = getAttrOfItemBykey(
          selected_filter_key,
          filters,
          "search_type"
        );

        if (
          current_URL_value !== __check_value ||
          current_URL_search_type !== __check_search_type
        ) {
          return false;
        } else {
          return true;
        }
    }
    return true;
  } else {
    return true;
  }
};
