import { Box, Flex, Heading } from "@chakra-ui/layout";
import React from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useQuery } from "@apollo/client";
import { GET_SALES_BY_TIME } from "../../../Apollo/typedefs";
import { decryptObject, encryptObject } from "../../../utils/crypt";
import useMessagesLocalStorage from "../../../hooks/useMessagesLocalStorage";
import { capitalizeFirstLetter } from "../../../utils/string";

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options: any = {
  plugins: {
    datalabels: {
      backgroundColor: function (context: any) {
        return context.dataset.backgroundColor;
      },
      borderRadius: 4,
      color: "white",
      font: {
        weight: "bold",
      },
      formatter: function (value: number) {
        return formatMoney(value);
      },
      padding: 6,
    },
  },

  // Core options
  aspectRatio: 5 / 3,
  layout: {
    padding: {
      top: 32,
      right: 16,
      bottom: 16,
      left: 8,
    },
  },
  elements: {
    line: {
      fill: false,
      tension: 0.4,
    },
  },
  scales: {
    y: {
      stacked: true,
    },
  },
};

interface chartSalesByTimeProps {
  type: string;
}

const ChartSalesByTime: React.FC<chartSalesByTimeProps> = ({ type }) => {
  const [challenge] = React.useState(encryptObject({ sales_by_time: type }));

  const _messages = useMessagesLocalStorage(
    `/lang/components/charts/chart_sales_by_time/`
  );

  const { data, error, loading } = useQuery(GET_SALES_BY_TIME, {
    variables: { challenge },
  });

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>error</span>;
  }

  if (_messages === "") {
    return <div>loarding...</div>;
  }

  if (data && data.getSalesByTime && _messages !== "") {
    const messages = JSON.parse(_messages);
    const {
      turnover,
      turnover_years_array,
      turnover_amount_array,
      reservation_count,
    } = decryptObject(data.getSalesByTime);

    // console.log({
    //   turnover,
    //   turnover_years_array,
    //   turnover_amount_array,
    //   reservation_count,
    // });

    const labels = [...turnover_years_array];

    const data_chart: any = {
      labels,
      datasets: [
        {
          label: "turnover",
          data: [...turnover_amount_array],
          borderColor: "#007bff",
          backgroundColor: "rgba(0, 123, 255, 0.5)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(0, 123, 255, 0.4)",
          hoverBorderColor: "rgba(0, 123, 255,1)",
          datalabels: {
            align: "end",
            anchor: "start",
          },
        },
      ],
    };
    return (
      <Flex direction="column" w="50%" boxShadow="md" p="32px" bg="white">
        <Flex
          mb="16px"
          justifyContent="space-between"
          alignItems="center"
          w="100%"
        >
          <Box>
            <Heading fontWeight="medium" size="md">
              {capitalizeFirstLetter(messages["sales_overview"])}
            </Heading>
            <Heading fontWeight="light" color="gray.600" size="xs">
              {capitalizeFirstLetter(messages["in_all_sales_of_reservations"])}
            </Heading>
          </Box>
          <Box>{capitalizeFirstLetter(messages[type])}</Box>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" w="100%">
          <Heading color="brand.500" fontWeight="normal" size="xl">
            {formatMoney(turnover)}
          </Heading>
          <Box>
            <Heading color="brand.500" fontWeight="medium" size="md">
              {reservation_count} {messages["reservations"]}
            </Heading>
          </Box>
        </Flex>
        <Flex boxShadow="xs" mt="16px" minH="250px" w="100%" bg="gray.100">
          <Bar options={options} data={data_chart} type="bar" />
        </Flex>
      </Flex>
    );
  } else {
    return <span>error 0</span>;
  }
};

export const formatMoney = (money: number) => {
  var __formatter = new Intl.NumberFormat("fr-BE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return __formatter.format(money); /* $2,500.00 */
};

export default ChartSalesByTime;
