import React from "react";
import { Box, Flex, Heading } from "@chakra-ui/layout";

import AddProductComponent from "./AddProduct";
import ProductsTableComponent from "./ProductsTable";
import { capitalizeFirstLetter } from "../../utils/string";
interface ProductsPageProps {
  messages: any;
}

const ProductsPage: React.FC<ProductsPageProps> = ({ messages }) => {
  return (
    <Box mb="64px">
      <Heading size="xl" pt="23px" pl="32px">
        {capitalizeFirstLetter(messages["products"])}
      </Heading>

      <Flex pt="32px">
        <AddProductComponent messages={messages} />
      </Flex>
      <Flex pt="32px" px="32px">
        <ProductsTableComponent messages={messages} />
      </Flex>
    </Box>
  );
};

export default ProductsPage;
