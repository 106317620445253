import React from "react";
import moment from "moment";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/da";
import "moment/locale/hu";
import "moment/locale/nn";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/fi";
import "moment/locale/sv";

import ModalPreview from "./ModalPreview";
import { MdDelete, MdDownload, MdEdit } from "react-icons/md";
import { Flex } from "@chakra-ui/layout";
import { Icon, Button } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "../../Components/ResponsiveTable";

import { useQuery } from "@apollo/client";
import { decryptObject, encryptObject } from "../../utils/crypt";
import { DELETE_PRICE, PRICES_QUERY } from "../../Apollo/typedefs";
import NProgressComponent from "../../App/Layout/NProgress";
import PaginationComponent from "../../Components/Pagination";
import { useLocation, useNavigate } from "react-router";
import DeleteDialog from "../../Components/DeleteDialog";
import { downloadAsJSON } from "../../utils/download_as_json";
import SimpleFilterComponent from "../../Components/Filter/simple";
import NoContentIllustration from "../../Components/NoContentIllustration";
import FormComponent, { priceRequestType } from "./form";
import DisplayErrorLangContainer from "../../Components/DisplayError/containers/lang";
import useLang from "../../hooks/useLang";
import { capitalizeFirstLetter } from "../../utils/string";

interface PricesTableProps {
  messages: any;
}

let default_challenge = encryptObject({
  page: 1,
  limit: 10,
});

const PricesTableComponent: React.FC<PricesTableProps> = ({ messages }) => {
  const lang = useLang();
  moment.locale(lang === "no" ? "nn" : lang);

  const onClose = () => setCurrentPriceToDelete(null);
  const navigateTo = useNavigate();

  const [currentPriceToDelete, setCurrentPriceToDelete] = React.useState<{
    id: string;
    name: string;
    data: string;
  } | null>(null);

  const [price_data_to_edit, set_price_data_to_edit] =
    React.useState<priceRequestType | null>(null);

  let defaultSearch: string = "";

  let challenge: string | null = null;
  const location = useLocation();

  if (location.search) {
    const params = new URLSearchParams(location.search);
    if (params.has("q")) {
      // challenge = decryptObject(params.get("q"));
      challenge = params.get("q");
      const raw_challenge = decryptObject(params.get("q"));
      if (raw_challenge && raw_challenge.filter && raw_challenge.filter.name) {
        defaultSearch = raw_challenge.filter.name;
      }
    }
  }

  const on_search = (clear: boolean = false) => {
    let selected_challenge = challenge
      ? decryptObject(challenge)
      : decryptObject(default_challenge);
    // console.log("selected_challenge=>", selected_challenge);

    if (clear) {
      delete selected_challenge.filter;
    }

    const addtionalFilter = clear
      ? null
      : {
          filter: {
            name: search,
          },
        };
    // console.log("addtionalFilter=>", addtionalFilter);

    const final_challenge = {
      ...addtionalFilter,
      ...selected_challenge,
    };

    // console.log("final_challenge=>", final_challenge);

    navigateTo(`/prices?q=${encryptObject({ ...final_challenge })}`);
  };

  const { data, error, loading } = useQuery(PRICES_QUERY, {
    variables: { challenge: challenge ? challenge : default_challenge },
  });

  const [search, set_search] = React.useState(defaultSearch);

  if (loading) {
    return <NProgressComponent />;
  }

  if (error) {
    return <DisplayErrorLangContainer error={error} />;
  }

  if (data && data.prices) {
    const prices = decryptObject(data.prices).prices;
    const pages = decryptObject(data.prices).pages;
    // console.log("data=>", decryptObject(data.prices));

    return (
      <Flex w="100%" direction="column">
        <SimpleFilterComponent
          on_search={on_search}
          search={search}
          set_search={set_search}
          label={messages["name_contains"]}
          row_height={48}
        />
        {(!prices || !pages) && (
          <NoContentIllustration hint="try to clear filter" />
        )}
        {prices && pages && (
          <>
            <Table w="100%" variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th fontSize="16px">
                    {capitalizeFirstLetter(messages["role"])}
                  </Th>
                  <Th fontSize="16px">
                    {capitalizeFirstLetter(messages["color"])}
                  </Th>
                  <Th fontSize="16px">
                    {capitalizeFirstLetter(messages["name"])}
                  </Th>
                  <Th fontSize="16px">
                    {capitalizeFirstLetter(messages["period"])}
                  </Th>
                  <Th fontSize="16px">
                    {capitalizeFirstLetter(messages["createdAt"])}
                  </Th>
                  <Th fontSize="16px">
                    {capitalizeFirstLetter(messages["action"])}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {prices.map((priceItem: any, i: any) => (
                  <Tr
                    id={priceItem.role === "STANDARD" ? "STANDARD" : `tr-${i}`}
                    key={i}
                  >
                    <Td>{messages[priceItem.role].toUpperCase()} </Td>
                    <Td>
                      <Flex h="20px" w="50px" bg={priceItem.color}></Flex>
                    </Td>
                    <Td>{priceItem.name}</Td>
                    {priceItem.role !== "STANDARD" && (
                      <Td>
                        {moment(priceItem.from_int, "YYYYMMDD").locale("en").format(
                          "DD MMM YYYY"
                        )}{" "}
                        -{" "}
                        {moment(priceItem.until_int, "YYYYMMDD").locale("en").format(
                          "DD MMM YYYY"
                        )}
                      </Td>
                    )}
                    {priceItem.role === "STANDARD" && <Td>+∞</Td>}
                    <Td>
                      {moment(priceItem.createdAt).locale("en").format(
                        "dddd DD MMM YYYY - HH:mm"
                      )}
                    </Td>
                    <Td>
                      <Button
                        p="0"
                        boxShadow="0"
                        _focus={{ boxShadow: "0" }}
                        variant="ghost"
                        rounded="full"
                        onClick={() => set_price_data_to_edit(priceItem)}
                      >
                        <Icon as={MdEdit} w="6" h="6" p="8pw" />
                      </Button>
                      <Button
                        p="0"
                        boxShadow="0"
                        _focus={{ boxShadow: "0" }}
                        variant="ghost"
                        rounded="full"
                        onClick={() =>
                          downloadAsJSON(
                            "BACKUP-" +
                              priceItem.name +
                              moment().locale("en").format("DD-MM-YYYY") +
                              ".json",
                            JSON.stringify(
                              JSON.parse(priceItem.prices || "{}"),
                              null,
                              4
                            )
                          )
                        }
                      >
                        <Icon as={MdDownload} w="6" h="6" p="8pw" />
                      </Button>
                      <ModalPreview messages={messages} priceItem={priceItem} />
                      {priceItem.role !== "STANDARD" && (
                        <Button
                          p="0"
                          boxShadow="0"
                          _focus={{ boxShadow: "0" }}
                          variant="ghost"
                          rounded="full"
                          // onClick={() => setIsOpen(true)}
                          onClick={() => {
                            if (
                              priceItem &&
                              priceItem.id &&
                              priceItem.name &&
                              priceItem.prices
                            ) {
                              setCurrentPriceToDelete({
                                id: priceItem.id,
                                name: priceItem.name,
                                data: priceItem.prices,
                              });
                            }
                          }}
                        >
                          <Icon as={MdDelete} w="6" h="6" p="8pw" />
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <PaginationComponent
              entity="prices"
              messages={messages}
              pages={pages}
            />
            <DeleteDialog
              messages={messages}
              id={currentPriceToDelete && currentPriceToDelete.id}
              data={currentPriceToDelete && currentPriceToDelete.data}
              content={currentPriceToDelete && currentPriceToDelete.name}
              type="price"
              onClose={onClose}
              isOpen={currentPriceToDelete !== null}
              DELETE_MUTATION={DELETE_PRICE}
              LIST_QUERY={PRICES_QUERY}
            />
            <FormComponent
              messages={messages}
              data={price_data_to_edit}
              type="update"
              cleanup={() => set_price_data_to_edit(null)}
            />
          </>
        )}
      </Flex>
    );
  } else {
    return <div>err</div>;
  }
};

export default PricesTableComponent;
