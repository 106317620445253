import React from "react";

import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Button,
  Icon,
} from "@chakra-ui/react";
import { MdViewHeadline } from "react-icons/md";
import { capitalizeFirstLetter } from "../../utils/string";
import useLang from "../../hooks/useLang";

interface ProductsModalPreviewProps {
  products: any;
  messages: any;
}

const ProductsModalPreview: React.FC<ProductsModalPreviewProps> = ({
  products,
  messages,
}) => {
  const {
    isOpen: isPreviewOpen,
    onOpen: onPreviewOpen,
    onClose: onPreviewClose,
  } = useDisclosure();

  const [, setPreviewData] = React.useState<any>();
  const lang = useLang();
  return (
    <>
      <Button
        p="0"
        boxShadow="0"
        _focus={{ boxShadow: "0" }}
        variant="ghost"
        rounded="full"
      >
        <Icon
          onClick={() => {
            setPreviewData({
              products,
            });
            onPreviewOpen();
          }}
          as={MdViewHeadline}
          w="6"
          h="6"
          p="8pw"
        />
      </Button>
      <Modal isOpen={isPreviewOpen} onClose={onPreviewClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {products.length} {messages["products"]}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex
              border="0.125px solid"
              borderColor="gray.100"
              direction="column"
              bg="gray.50"
              px="8px"
              pb="32px"
              style={{ overflowY: "scroll", maxHeight: "80vh" }}
            >
              <table>
                <thead>
                  <tr>
                    <td
                      style={{
                        paddingTop: "32px",
                        paddingBottom: "8px",
                      }}
                    >
                      <b>{capitalizeFirstLetter(messages["name"])}</b>
                    </td>
                    <td
                      style={{
                        paddingTop: "32px",
                        paddingBottom: "8px",
                      }}
                    >
                      <b>{capitalizeFirstLetter(messages["price"])} (€)</b>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {products !== undefined &&
                    products !== null &&
                    products.map((product: any, idx: number) => (
                      <tr
                        style={{
                          borderBottom: "0.125px solid #ddd",
                        }}
                        key={idx + `product-key`}
                      >
                        <td
                          style={{
                            paddingTop: "6px",
                            paddingBottom: "6px",
                          }}
                        >
                          {JSON.parse(product.name)[lang]}
                        </td>
                        <td
                          style={{
                            paddingTop: "6px",
                            paddingBottom: "6px",
                          }}
                        >
                          {product.price}€
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProductsModalPreview;
