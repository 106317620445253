import React from "react";
import useMessagesLocalStorage from "../../hooks/useMessagesLocalStorage";

interface GetTranslatedCountryProps {
  code: string;
}

const GetTranslatedCountry: React.FC<GetTranslatedCountryProps> = ({
  code,
}) => {
  const messages = useMessagesLocalStorage(`/lang/components/countries/`);
  if (messages.length < 1) {
    return <div>loading...</div>;
  } else {
    const countries = JSON.parse(messages);
    const _country = countries.filter((el: any) => el.code === code)[0].name;
    return <span>{_country}</span>;
  }
};

export default GetTranslatedCountry;
