import ReactDOM from "react-dom";

import { ApolloProvider } from "@apollo/client";
import client from "./Apollo/client";

import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import "./theme/index.css";
import GuardComponent from "./Guard";

import ShieldLangContainer from "./shield/containers/lang";

let RenderedApp = <div></div>;

const secr = localStorage.getItem("ga__54684565");
if (secr) {
  RenderedApp = (
    <ChakraProvider theme={theme}>
      <ShieldLangContainer secr={secr} />
    </ChakraProvider>
  );
} else {
  RenderedApp = (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <GuardComponent />
      </ChakraProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(RenderedApp, document.getElementById("root"));
