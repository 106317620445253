import PricesPage from "..";
import useMessagesLocalStorage from "../../../hooks/useMessagesLocalStorage";

const PricesPageLangContainer = (props: any) => {
  const messages = useMessagesLocalStorage(`/lang/pages/prices/`);

  if (messages === "") {
    return <div>loarding...</div>;
  } else {
    return <PricesPage {...props} messages={JSON.parse(messages)} />;
  }
};

export default PricesPageLangContainer;
