import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router";
import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import IndexPage from "../pages";
import LayoutComponent from "./Layout";

import NProgressComponent from "./Layout/NProgress";
import ProductsPageLangContainer from "../pages/Products/containers/lang";
import SettingsPageLangContainer from "../pages/Settings/containers/lang";
import AvailabilityLangContainer from "../pages/Availability/containers/lang";
import PricesPageLangContainer from "../pages/Prices/containers/lang";
import ReservationsPageLangContainer from "../pages/Reservations/containers/lang";

const timeout = { enter: 300, exit: 200 };

const App: React.FC = () => {
  const location = useLocation();
  const currentKey = location.pathname.split("/")[1] || "/";

  return (
    <LayoutComponent>
      <TransitionGroup component="main" className="page-main">
        <CSSTransition
          key={currentKey}
          timeout={timeout}
          classNames="fade"
          appear
        >
          <section className="page-main-inner">
            <React.Suspense fallback={<NProgressComponent />}>
              <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route
                  path="/reservations"
                  element={<ReservationsPageLangContainer />}
                />
                <Route
                  path="/reservations/advanced"
                  element={<ReservationsPageLangContainer />}
                />
                <Route path="/prices" element={<PricesPageLangContainer />} />
                <Route
                  path="/availability"
                  element={<AvailabilityLangContainer />}
                />
                <Route
                  path="/products"
                  element={<ProductsPageLangContainer />}
                />
                <Route
                  path="/settings"
                  element={<SettingsPageLangContainer />}
                />
                <Route path="*" element={<div>404 page not found</div>} />
              </Routes>
            </React.Suspense>
          </section>
        </CSSTransition>
      </TransitionGroup>
    </LayoutComponent>
  );
};

export default App;
