import React from "react";
import { Box, Flex, Heading } from "@chakra-ui/layout";

import AddPriceComponent from "./AddPrice";
import PricesTableComponent from "./PricesTable";
import { capitalizeFirstLetter } from "../../utils/string";

interface PricesPageProps {
  messages: any;
}

const PricesPage: React.FC<PricesPageProps> = ({ messages }) => {
  return (
    <Box mb="64px">
      <Heading size="xl" pt="23px" pl="32px">
        {capitalizeFirstLetter(messages["prices"])}
      </Heading>

      <Flex pt="32px">
        <AddPriceComponent messages={messages} />
      </Flex>
      <Flex pt="32px" px="32px">
        <PricesTableComponent messages={messages} />
      </Flex>
    </Box>
  );
};

export default PricesPage;
