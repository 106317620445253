import React from "react";

import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Button,
  Icon,
} from "@chakra-ui/react";
import { MdViewHeadline } from "react-icons/md";
import { capitalizeFirstLetter } from "../../utils/string";

interface ModalPreviewProps {
  priceItem: any;
  messages: any;
}

const ModalPreview: React.FC<ModalPreviewProps> = ({ priceItem, messages }) => {
  const {
    isOpen: isPreviewOpen,
    onOpen: onPreviewOpen,
    onClose: onPreviewClose,
  } = useDisclosure();

  const [previewData, setPreviewData] =
    React.useState<{ name: string; data: string[] }>();

  return (
    <>
      <Button
        p="0"
        boxShadow="0"
        _focus={{ boxShadow: "0" }}
        variant="ghost"
        rounded="full"
      >
        <Icon
          onClick={() => {
            setPreviewData({
              name: priceItem.name,
              data: JSON.parse(priceItem.prices),
            });
            onPreviewOpen();
          }}
          as={MdViewHeadline}
          w="6"
          h="6"
          p="8pw"
        />
      </Button>
      <Modal isOpen={isPreviewOpen} onClose={onPreviewClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{previewData?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex
              border="0.125px solid"
              borderColor="gray.100"
              direction="column"
              bg="gray.50"
              px="100px"
              style={{overflowY: "scroll", maxHeight:"80vh"}}
            >
              <table >
                <thead>
                  <tr>
                    <td
                      style={{
                        paddingTop: "32px",
                        paddingBottom: "8px",
                      }}
                    >
                      <b>{capitalizeFirstLetter(messages["day"])}</b>
                    </td>
                    <td
                      style={{
                        paddingTop: "32px",
                        paddingBottom: "8px",
                      }}
                    >
                      <b>{capitalizeFirstLetter(messages["price"])} (€)</b>
                    </td>
                  </tr>
                </thead>
                <tbody >
                  {previewData?.data.map((priceElement, idx) => (
                    <tr
                      style={{
                        borderBottom: "0.125px solid #ddd",
                      }}
                      key={idx}
                    >
                      <td
                        style={{
                          paddingTop: "6px",
                          paddingBottom: "6px",
                        }}
                      >
                        {idx + 1}
                      </td>
                      <td
                        style={{
                          paddingTop: "6px",
                          paddingBottom: "6px",
                        }}
                      >
                        {priceElement}€
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalPreview;
