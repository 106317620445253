import { Flex, Select, Input } from "@chakra-ui/react";
import React from "react";
import {
  getAttrOfItemBykey,
  getValueOfItemBykey,
} from "../../../../../utils/object";
import { number_filter_search_types } from "../../../constants";
import { FilterType } from "../../../types";
import { getDisabledState } from "../../../utils/getDisabledState";
import { updateFilterSearchType, updateFilterValue } from "../utils";

interface FilterModalInputTypeNumberProps {
  messages: any;
  selected_filter_key: string;
  local_filters: FilterType[] | null;
  set_local_filters: React.Dispatch<React.SetStateAction<FilterType[] | null>>;
  on_filter: (local_filters: any, clear?: boolean | undefined) => void;
}

const FilterModalInputTypeNumber: React.FC<FilterModalInputTypeNumberProps> = ({
  messages,
  selected_filter_key,
  local_filters,
  set_local_filters,
  on_filter,
}) => {
  const current_search_type = getAttrOfItemBykey(
    selected_filter_key,
    local_filters,
    "search_type"
  );

  const current_value =
    getValueOfItemBykey(selected_filter_key, local_filters) || "";

  return (
    <Flex direction="column">
      <Flex w="100%" pb="8px">
        <Select
          defaultValue={current_search_type}
          w="100%"
          onChange={(e) =>
            e.target.value &&
            updateFilterSearchType(
              e.target.value,
              selected_filter_key,
              local_filters,
              set_local_filters
            )
          }
        >
          {number_filter_search_types.map((el) => (
            <option key={`key-${el}`} value={`${el}`}>
              {messages[el]}
            </option>
          ))}
        </Select>
      </Flex>

      {current_search_type !== "in" && current_search_type !== "out" && (
        <Flex>
          <Input
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                !getDisabledState(selected_filter_key, local_filters)
              ) {
                on_filter(local_filters);
              }
            }}
            type="text"
            value={current_value}
            onChange={(e) =>
              updateFilterValue(
                e.target.value,
                selected_filter_key,
                local_filters,
                set_local_filters
              )
            }
            variant="flushed"
            placeholder={`${getAttrOfItemBykey(
              selected_filter_key,
              local_filters,
              "name"
            )}..`}
          />
        </Flex>
      )}

      {(current_search_type === "in" || current_search_type === "out") && (
        <Flex w="100%" alignItems="center" justifyContent="center">
          [
          <Flex px="16px">
            <Input
              w="75px"
              value={(current_value && JSON.parse(current_value))[0] || ""}
              onWheel={() => {
                (document.activeElement as HTMLElement).blur();
              }}
              min="0"
              type="number"
              onChange={(e) => {
                if (e.target.value !== null && +e.target.value >= 0) {
                  const value__ = JSON.stringify([
                    +e.target.value,
                    +JSON.parse(current_value)[1] || "",
                  ]);

                  updateFilterValue(
                    value__,
                    selected_filter_key,
                    local_filters,
                    set_local_filters
                  );
                }
              }}
              variant="flushed"
              placeholder={`${selected_filter_key}..`}
            />
          </Flex>{" "}
          ,{" "}
          <Flex px="16px">
            <Input
              w="75px"
              value={(current_value && JSON.parse(current_value))[1] || ""}
              onWheel={() => {
                (document.activeElement as HTMLElement).blur();
              }}
              min="0"
              type="number"
              onChange={(e) => {
                if (e.target.value !== null && +e.target.value >= 0) {
                  const value__ = JSON.stringify([
                    +JSON.parse(current_value)[0] || "",
                    +e.target.value,
                  ]);

                  updateFilterValue(
                    value__,
                    selected_filter_key,
                    local_filters,
                    set_local_filters
                  );
                }
              }}
              variant="flushed"
              placeholder={`${selected_filter_key}..`}
            />
          </Flex>
          ]
        </Flex>
      )}
    </Flex>
  );
};

export default FilterModalInputTypeNumber;
