import { TableColumnHeaderProps, Th as ChakraTh } from "@chakra-ui/react";

export const Th = (props: TableColumnHeaderProps) => (
  <ChakraTh
    {...props}
    borderColor="gray.200"
    borderRight="1px"
    borderRightColor="gray.200"
    textAlign="center"

    px="6px"
    style={{ wordWrap: "normal" }}
  />
);
