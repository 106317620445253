import { Button, ButtonProps } from "@chakra-ui/button";

const RippleButton = (props: ButtonProps) => {
  return (
    <Button {...props} className="ripple">
      {props.children}
    </Button>
  );
};

export default RippleButton;
