import React from "react";
import useMessagesLocalStorage from "../../hooks/useMessagesLocalStorage";

interface GetDialCodeByCountryCodeProps {
  code: string;
}

const GetDialCodeByCountryCode: React.FC<GetDialCodeByCountryCodeProps> = ({
  code,
}) => {
  const messages = useMessagesLocalStorage(`/lang/components/phones/`);
  if (messages.length < 1) {
    return <div>loading...</div>;
  } else {
    const phones = JSON.parse(messages);
    // console.log(phones);

    const dialCode = phones.filter((el: any) => el.code === code)[0].dial_code;
    
    return <span>{dialCode}</span>;
  }
};

export default GetDialCodeByCountryCode;
