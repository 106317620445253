import React from "react";

import {
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Spacer,
  Input,
  Flex,
  Box,
  ModalFooter,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useDisclosure } from "@chakra-ui/hooks";

import moment from "moment";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/da";
import "moment/locale/hu";
import "moment/locale/nn";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/fi";
import "moment/locale/sv";

import { MdOutlineAddchart } from "react-icons/md";
import { SketchContanier } from "./styles";

import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import ColorPicker from "../../Components/ColorPicker";
import { useMutation } from "@apollo/client";
import {
  CREATE_PRICE,
  PRICES_QUERY,
  UPDATE_PRICE,
} from "../../Apollo/typedefs";
import { decryptObject, encryptObject } from "../../utils/crypt";
import RippleButton from "../../Components/RippleButton";
import { capitalizeFirstLetter } from "../../utils/string";
import useLang from "../../hooks/useLang";

interface priceObject {
  day: number;
  price: number | null;
}

export type priceRequestType = {
  id?: string | null;
  role?: string;
  name: string;
  from: string;
  from_int: number;
  until: string;
  until_int: number;
  color: string;
  prices: string | null;
};

interface FormComponentProps {
  type: "create" | "update";
  data?: priceRequestType | null;
  cleanup?: () => void | null;
  messages: any;
}

const FormComponent: React.FC<FormComponentProps> = ({
  type,
  children,
  data,
  cleanup,
  messages,
}) => {
  const lang = useLang();
  moment.locale(lang === "no" ? "nn" : lang);

  const [mutationFunction, { loading }] = useMutation(
    type === "create" ? CREATE_PRICE : UPDATE_PRICE,

    {
      refetchQueries: [PRICES_QUERY],
    }
  );

  let mutationName = type === "create" ? "createPrice" : "updatePrice";

  let validation = false;

  const toast = useToast();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const parsePricesForDOM = (prices: string | null) =>
    prices &&
    JSON.parse(prices).map((price: number | null, i: number) => {
      if (price) {
        return { day: i + 1, price: +price };
      } else {
        return null;
      }
    });

  const [role, set_role] = React.useState("NORMAL");
  const [id, set_id] = React.useState<string | null>(null);
  const [name, setName] = React.useState("");

  const [name_touched, set_name_touched] = React.useState(false);

  const [datetime_range, set_datetime_range] = React.useState([
    new Date(),
    new Date(),
  ]);

  const [color, setColor] = React.useState("#007bff");

  const [prices, setPrices] = React.useState<priceObject[]>([
    { day: 1, price: null },
  ]);

  if (name.length < 3 || (prices.length === 1 && prices[0].price === null)) {
    validation = false;
  } else {
    validation = true;
  }

  const onCancel = () => {
    onClose();
    cleanup && cleanup();
    set_datetime_range([new Date(), new Date()]);
    setColor("#007bff");
    setName("");
    setPrices([{ day: 1, price: null }]);
    setTimeout(() => {
      set_name_touched(false);
    }, 310);
  };

  const onSave = async () => {
    set_name_touched(true);
    try {
      if (!loading && mutationFunction !== null) {
        const pricesToServer = prices.map((el) => `${el.price}`);
        const priceRequest: priceRequestType = {
          id,
          name,
          role,
          from: datetime_range[0].toString(),
          from_int: +moment(datetime_range[0]).locale("en").format("YYYYMMDD"),
          until: datetime_range[1].toString(),
          until_int: +moment(datetime_range[1]).locale("en").format("YYYYMMDD"),
          color,
          prices: JSON.stringify(pricesToServer),
        };

        const { data } = await mutationFunction({
          variables: {
            challenge: encryptObject({ ...priceRequest }),
          },
        });
        if (!data) {
          // console.log("something went wrong 01");
        }
        // console.log(data);

        const { msg } = decryptObject(data[mutationName]);
        if (!msg) {
          // console.log("something went wrong 02");
        }

        if (msg === type + "d") {
          onCancel();

          toast({
            title: `Price ${type}d.`,
            description: `We've ${type}d your Price for you.`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const lastPriceInputRef = React.useRef<HTMLInputElement>(null);
  const [isEditingOnInput, setIsEditingOnInput] = React.useState(false);

  React.useEffect(() => {
    if (lastPriceInputRef.current !== null && isEditingOnInput) {
      lastPriceInputRef.current.focus();
    }
    return () => {
      setIsEditingOnInput(false);
    };
  }, [isEditingOnInput]);

  React.useEffect(() => {
    if (type === "update" && data) {
      onOpen();

      // console.log(data.role);

      set_role(data.role || "NORMAL");
      setName(data.name);

      if (typeof data.id === "string") {
        set_id(data.id || null);
      }

      if (data.role === "NORMAL") {
        set_datetime_range([new Date(data.from), new Date(data.until)]);
      }

      setColor(data.color);
      setPrices(parsePricesForDOM(data.prices));
    }
    return () => {};
  }, [onOpen, type, data]);

  const ErrorMessageForPrices: React.FC<{ idx: number }> = ({ idx }) => {
    let returnJSXIfCurrentlitterthanPrev = <div />;

    // let returnJSXBefore = <div />;
    // let returnJSXCurrent = <div />;
    // let returnJSXAfter = <div />;

    // if (prices[idx - 1]) {
    //   returnJSXBefore = (
    //     <div>{`[${idx - 1}: ${prices[idx - 1].price}] << `}</div>
    //   );
    // }

    // returnJSXCurrent = <div>{` [${idx}: ${prices[idx].price}] `}</div>;

    // if (prices[idx + 1]) {
    //   returnJSXAfter = (
    //     <div>{` >> [${idx + 1}: ${prices[idx + 1].price}]`}</div>
    //   );
    // }

    if (prices[idx - 1] && prices[idx]) {
      if (prices[idx - 1].price! > prices[idx].price!) {
        returnJSXIfCurrentlitterthanPrev = (
          <div>
            {capitalizeFirstLetter(messages["the_price"])} {prices[idx].price}€{" "}
            {messages["is_greater_than"]} {prices[idx - 1].price}€{" "}
            {messages["of_day"]} {prices[idx - 1].day}
          </div>
        );
      }
    }

    return (
      <Flex color="red.500" fontWeight="500" fontStyle="italic" px="8px">
        {/* {returnJSXBefore}
          {returnJSXCurrent}
          {returnJSXAfter} */}
        {returnJSXIfCurrentlitterthanPrev}
      </Flex>
    );
  };

  return (
    <>
      <Flex onClick={() => onOpen()}>{children}</Flex>
      <Modal
        size="xl"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onCancel}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {capitalizeFirstLetter(messages[`${type}_price`])}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {role === "NORMAL" && (
              <>
                <FormControl pb="16px" alignItems="center">
                  <Flex>
                    <FormLabel minW="64px">
                      {capitalizeFirstLetter(messages["name"])}
                    </FormLabel>
                    <Spacer />
                    <Input
                      onBlur={() => {
                        setTimeout(() => {
                          set_name_touched(true);
                        }, 300);
                      }}
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      ref={initialRef}
                      placeholder={`${capitalizeFirstLetter(
                        messages["name"]
                      )}...`}
                    />
                  </Flex>
                  {name_touched && (
                    <Flex pt="8px" color="red">
                      {name.length < 3 &&
                        messages["please_provide_a_name_at_least_3_caracters"]}
                    </Flex>
                  )}
                </FormControl>

                <FormControl pb="32px" display="flex" alignItems="center">
                  <FormLabel minW="64px">
                    {capitalizeFirstLetter(messages["period"])}
                  </FormLabel>
                  <Flex direction="column">
                    <Flex mb="8px">
                      <b>
                        {moment(datetime_range[0]).locale("en").format("dddd DD MMM YYYY")}
                      </b>{" "}
                      &nbsp; - &nbsp;
                      <b>
                        {moment(datetime_range[1]).locale("en").format("dddd DD MMM YYYY")}
                      </b>
                    </Flex>
                    <DateRangePicker
                      prev2Label={null}
                      next2Label={null}
                      clearIcon={""}
                      minDate={new Date()}
                      onChange={set_datetime_range}
                      value={datetime_range}
                    />
                  </Flex>
                </FormControl>
              </>
            )}

            <FormControl pb="32px" display="flex" alignItems="center">
              <FormLabel minW="64px">
                {capitalizeFirstLetter(messages["color"])}
              </FormLabel>
              <SketchContanier
                style={{
                  background: color,
                }}
              >
                <ColorPicker
                  setColor={(co: any) => setColor(co)}
                  color={color}
                />
              </SketchContanier>
            </FormControl>

            <FormControl px="16py" display="flex" alignItems="center">
              <FormLabel minW="64px">
                {capitalizeFirstLetter(messages["prices"])}
              </FormLabel>
            </FormControl>
            <Flex
              border="0.125px solid"
              borderColor="gray.100"
              direction="column"
              ml={`${64 + 16}px`}
              bg="gray.50"
            >
              <Flex
                px="16px"
                py="8px"
                borderBottom="0.125px solid"
                borderColor="gray.100"
              >
                <Flex minW="64px">
                  {capitalizeFirstLetter(messages["day"])}
                </Flex>
                <Flex>{capitalizeFirstLetter(messages["price"])} (€)</Flex>
              </Flex>
              {prices.map((priceElement, idx) => (
                <Box key={idx}>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    borderBottom="0.125px solid"
                    borderColor="gray.100"
                    px="16px"
                    py="8px"
                  >
                    <Flex minW="64px">
                      <Text align="center" w="50%">
                        {priceElement.day}
                      </Text>
                    </Flex>
                    <Flex>
                      <Input
                        ref={
                          idx === prices.length - 1 ? lastPriceInputRef : null
                        }
                        onWheel={() => {
                          (document.activeElement as HTMLElement).blur();
                        }}
                        value={prices[idx].price || 0}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          const price = +e.target.value;
                          if (price > 0) {
                            let tmp: priceObject[] = [...prices];
                            tmp[idx] = {
                              day: idx + 1,
                              price,
                            };
                            setPrices(tmp);
                          }
                        }}
                        type="number"
                      />
                      <Flex></Flex>
                    </Flex>
                  </Flex>
                  <ErrorMessageForPrices idx={idx} />
                </Box>
              ))}
              <Flex
                px="16px"
                py="8px"
                borderBottom="0.125px solid"
                borderColor="gray.100"
              >
                <Flex minW="64px">
                  <Icon
                    onClick={(e) => {
                      let tmp: priceObject[] = [...prices];
                      tmp.push({
                        day: prices.length + 1,
                        price: prices[prices.length - 1].price,
                      });
                      setPrices(tmp);
                      setIsEditingOnInput(true);
                    }}
                    color="brand.500"
                    _hover={{
                      color: "brand.600",
                    }}
                    _active={{
                      color: "brand.400",
                    }}
                    cursor="pointer"
                    w="6"
                    h="6"
                    as={MdOutlineAddchart}
                  />
                </Flex>
                <Flex></Flex>
              </Flex>
            </Flex>
            {prices.length === 1 && prices[0].price === null && (
              <Flex pt="8px" color="red">
                {capitalizeFirstLetter(
                  messages["please_provide_a_price_at_least_for_the_first_day"]
                )}
              </Flex>
            )}
          </ModalBody>

          <ModalFooter>
            <RippleButton
              variant="unstyled"
              color="brand.500"
              disabled={!validation}
              onClick={onSave}
              isLoading={loading}
              colorScheme="brand"
              mr={3}
            >
              <Flex px="16px">{capitalizeFirstLetter(messages["save"])}</Flex>
            </RippleButton>
            <RippleButton color="black" variant="unstyled" onClick={onCancel}>
              <Flex px="16px">{capitalizeFirstLetter(messages["cancel"])}</Flex>
            </RippleButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FormComponent;
