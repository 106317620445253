import { useQuery } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { CURRENT_USER_QUERY } from "../Apollo/typedefs";
import App from "../App";
import NProgressComponent from "../App/Layout/NProgress";
import LoginPageLangContainer from "../Auth/containers/lang";
import DisplayErrorLangContainer from "../Components/DisplayError/containers/lang";
import { decryptObject } from "../utils/crypt";
// import { decryptObject } from "../Lib/crypt";

const GuardComponent = () => {
  const { loading, error, data } = useQuery(CURRENT_USER_QUERY);

  if (loading) {
    return <NProgressComponent />;
  }

  if (error) {
    return <DisplayErrorLangContainer error={error} />;
  }

  if (data && data.me) {
    const decryptedData = decryptObject(data.me);
    if (!decryptedData) return <div>"*ERC-4*"</div>;

    if (decryptedData.msg === "redirect_to_login") {
      return <LoginPageLangContainer />;
    } else if (decryptedData.msg === "redirect_to_app") {
      return (
        <BrowserRouter>
          <App />
        </BrowserRouter>
      );
    } else {
      return <div>*ERC-5*</div>;
    }
  } else {
    return <div>err</div>;
  }
};

export default GuardComponent;
