import { Table as ChakraTable, TableProps } from "@chakra-ui/react";
import { ResponsiveTableStyles } from "./styles";
import { Provider } from "./utils";

export const Table = (props: TableProps) => {
  const { className, ...rest } = props;
  const classes = `${className || ""} responsiveTable`;

  return (
    <ResponsiveTableStyles>
      <Provider value={{}}>
        <ChakraTable
          {...rest}
          className={classes}
          borderColor="gray.200"
          borderWidth="1px"
        />
      </Provider>
    </ResponsiveTableStyles>
  );
};
