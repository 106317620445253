import useMessagesLocalStorage from "../../../hooks/useMessagesLocalStorage";
import AvailabilityContainer from "./container";

const AvailabilityLangContainer = (props: any) => {
  const messages = useMessagesLocalStorage(`/lang/pages/availability/`);

  if (messages === "") {
    return <div>loarding...</div>;
  } else {
    return <AvailabilityContainer {...props} messages={JSON.parse(messages)} />;
  }
};

export default AvailabilityLangContainer;
