import { Flex } from "@chakra-ui/layout";
import React from "react";

interface DropdownMenuItemProps {}

const DropdownMenuItem: React.FC<DropdownMenuItemProps> = (
  { children },
  props
) => {
  return (
    <Flex
      {...props}
      flex="1"
      cursor="pointer"
      minH="32px"
      px="24px"
      py="8px"
      _hover={{ bg: "gray.50" }}
    >
      {children}
      <div></div>
    </Flex>
  );
};

export default DropdownMenuItem;
