import { Badge } from "@chakra-ui/layout";
import React from "react";
import {
  CANCELED,
  COMPLETED,
  IN_PROGRESS,
  StatusType,
  UPDATED,
} from "../../../constants";

interface GetColorForStatusProps {
  status: StatusType;
  name: string;
}

const getColor = (status: StatusType) => {
  switch (status) {
    case IN_PROGRESS:
      return "orange";

    case UPDATED:
      return "purple";

    case CANCELED:
      return "red";

    case COMPLETED:
      return "green";

    default:
      return "gray";
  }
};

const GetColorForStatus: React.FC<GetColorForStatusProps> = ({
  status,
  name,
}) => {
  return (
    <Badge  colorScheme={getColor(status)}>
      {name}
    </Badge>
  );
};

export default GetColorForStatus;
