import { Shield } from "..";
import useMessagesLocalStorage from "../../hooks/useMessagesLocalStorage";

const ShieldLangContainer = (props: any) => {
  const messages = useMessagesLocalStorage(`/lang/shield/`);

  if (messages === "") {
    return <div>loarding...</div>;
  } else {
    return <Shield {...props} messages={JSON.parse(messages)} />;
  }
};

export default ShieldLangContainer;
