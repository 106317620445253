import { Icon } from "@chakra-ui/react";
import { Flex, Text } from "@chakra-ui/layout";
import moment from "moment";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/da";
import "moment/locale/hu";
import "moment/locale/nn";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/fi";
import "moment/locale/sv";
import React from "react";
import { MdCancel } from "react-icons/md";
import { updateObject } from "../../../../utils/object";
import { FilterType } from "../../types";
import useLang from "../../../../hooks/useLang";
import getLangByCode from "../../../../lang/getLangByCode";
import GetTranslatedCountry from "../../../GetTranslatedCountry";
import GetDialCodeByCountryCode from "../../../GetDialCodeByCountryCode";

interface SearchItemProps {
  messages: any;
  filter: any;
  set_is_filter_menu_input_closed: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  set_selected_filter_key: React.Dispatch<React.SetStateAction<string | null>>;
  filters: FilterType[] | null;
  on_filter: (local_filters: any, clear?: boolean | undefined) => void;
}

const formatValue = (
  value: string,
  type: string,
  search_type: string,
  search_key: string,
  messages: any
) => {
  switch (type) {
    case "date":
      if (search_type === "default") {
        return messages[value];
      }
      if (
        ["equal", "not_equal", "gt", "gte", "lt", "lte"].includes(search_type)
      ) {
        return moment(value, "YYYYMMDD").locale("en").format("dddd DD MMM YYYY");
      }
      if (["in", "out"].includes(search_type)) {
        const __interval = JSON.parse(value);
        return `[ ${moment(__interval[0], "YYYYMMDD").locale("en").format(
          "dddd DD MMM YYYY"
        )} , ${moment(__interval[1], "YYYYMMDD").locale("en").format("dddd DD MMM YYYY")} ]`;
      }
      break;
    case "select":
      switch (search_key) {
        case "status":
          return messages[value];
        case "code_lang":
          return getLangByCode(value);
        case "country":
        case "business_country":
          return <GetTranslatedCountry code={value} />;
        case "code_country_phone_number":
          return <GetDialCodeByCountryCode code={value} />;
      }
  }
  return value;
};

const SearchItem: React.FC<SearchItemProps> = ({
  messages,
  filter,
  set_is_filter_menu_input_closed,
  set_selected_filter_key,
  filters,
  on_filter,
}) => {
  const lang = useLang();
  moment.locale(lang === "no" ? "nn" : lang);

  return (
    <Flex
      cursor="pointer"
      rounded="full"
      bg="gray.100"
      alignItems="center"
      px="8px"
      mx="8px"
      onClick={() => {
        set_is_filter_menu_input_closed(false);
        set_selected_filter_key(filter.key + "");
      }}
      _hover={{ bg: "gray.300" }}
    >
      <Text pl="6px" size="sm">
        {filter.name} {messages[filter.search_type]} '
        {formatValue(
          filter.value,
          filter.type,
          filter.search_type,
          filter.key,
          messages
        )}
        '
      </Text>
      <Flex p="6px">
        <Icon
          onClick={() => {
            const newUpdate: FilterType = {
              key: filter.key,
              name: filter.name,
              search_type: filter.search_type,
              type: filter.type,
              value: null,
            };

            // console.log("vvv newUpdate", newUpdate);

            const newUpdatedObj = updateObject(newUpdate, filters);

            // console.log("vvv newUpdatedObj", newUpdatedObj);

            on_filter([...newUpdatedObj], true);
          }}
          as={MdCancel}
          w="5"
          h="5"
          color="gray.600"
        />
      </Flex>
    </Flex>
  );
};

export default SearchItem;
