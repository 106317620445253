import React from "react";
import moment from "moment";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/ar";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/da";
import "moment/locale/hu";
import "moment/locale/nn";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/fi";
import "moment/locale/sv";

import {
  MdAirplanemodeInactive,
  MdAirplaneTicket,
  MdManageSearch,
  MdNotInterested,
  MdOutlineAirplanemodeInactive,
  MdOutlineAirplaneTicket,
  MdOutlineCalendarToday,
  MdOutlinePeople,
  MdCancel,
  MdEdit,
  MdPrint,
} from "react-icons/md";
import { Badge, Flex, Link } from "@chakra-ui/layout";
import { Icon, Button } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "../../../Components/ResponsiveTable";

import { useQuery } from "@apollo/client";
import { decryptObject, encryptObject } from "../../../utils/crypt";
import { RESERVATIONS_QUERY } from "../../../Apollo/typedefs";
import NProgressComponent from "../../../App/Layout/NProgress";
import PaginationComponent from "../../../Components/Pagination";
import { useLocation, useNavigate } from "react-router";
import NoContentIllustration from "../../../Components/NoContentIllustration";
import FilterComponent from "../../../Components/Filter";
import GetColorForStatus from "../utils/GetColorForStatus";
import DisplayErrorLangContainer from "../../../Components/DisplayError/containers/lang";
import { FilterType } from "../../../Components/Filter/types";
import useLang from "../../../hooks/useLang";
import { capitalizeFirstLetter } from "../../../utils/string";
import RippleButton from "../../../Components/RippleButton";
import getLangByCode from "../../../lang/getLangByCode";
import GetTranslatedCountry from "../../../Components/GetTranslatedCountry";
import GetDialCodeByCountryCode from "../../../Components/GetDialCodeByCountryCode";
import PriceContainerByID from "../../../Components/PriceContainerByID";
import ProductsModalPreviewContainer from "../../../Components/ProductsModalPreview/container";
import { config } from "../../../config";

let default_challenge = encryptObject({
  page: 1,
  limit: 10,
});

const declarate_filters = [
  {
    key: "start_date",
    name: "start_date",
    search_type: "default",
    type: "date",
  },
  {
    key: "end_date",
    name: "end_date",
    search_type: "default",
    type: "date",
  },
  {
    key: "num_ticket",
    name: "ticket",
    search_type: "equal",
    type: "string",
  },
  {
    key: "full_name",
    name: "full_name",
    search_type: "contains",
    type: "string",
  },
  {
    key: "email",
    name: "email",
    search_type: "contains",
    type: "string",
  },
  {
    key: "full_phone_number",
    name: "full_phone_number",
    search_type: "contains",
    type: "string",
  },
  {
    key: "createdAt",
    name: "createdAt",
    search_type: "equal",
    type: "date",
  },
  {
    key: "updatedAt",
    name: "updatedAt",
    search_type: "equal",
    type: "date",
  },
  {
    key: "total_price",
    name: "total_price",
    search_type: "equal",
    type: "number",
  },
  {
    key: "status",
    name: "status",
    search_type: "equal",
    type: "select",
  },
  {
    key: "number_of_people",
    name: "number_of_people",
    search_type: "equal",
    type: "number",
  },
  {
    key: "days_in_parking",
    name: "days_in_parking",
    search_type: "equal",
    type: "number",
  },
  {
    key: "flight_number_at_start",
    name: "flight_number_at_start",
    search_type: "contains",
    type: "string",
  },
  {
    key: "flight_number_at_end",
    name: "flight_number_at_end",
    search_type: "contains",
    type: "string",
  },
  {
    key: "travel_for",
    name: "travel_for",
    search_type: "equal",
    type: "select",
  },
  {
    key: "code_lang",
    name: "langue",
    search_type: "equal",
    type: "select",
  },
  {
    key: "country",
    name: "country",
    search_type: "equal",
    type: "select",
  },
  {
    key: "pure_phone_number",
    name: "pure_phone_number",
    search_type: "equal",
    type: "string",
  },
  {
    key: "code_country_phone_number",
    name: "code_country_phone_number",
    search_type: "equal",
    type: "select",
  },
  {
    key: "company_name",
    name: "company_name",
    search_type: "contains",
    type: "string",
  },
  {
    key: "address",
    name: "address",
    search_type: "contains",
    type: "string",
  },
  {
    key: "address_two",
    name: "address_two",
    search_type: "contains",
    type: "string",
  },
  {
    key: "zip_code",
    name: "zip_code",
    search_type: "contains",
    type: "string",
  },
  {
    key: "city",
    name: "city",
    search_type: "contains",
    type: "string",
  },
  {
    key: "business_country",
    name: "business_country",
    search_type: "equal",
    type: "select",
  },
  {
    key: "vat_number",
    name: "vat_number",
    search_type: "contains",
    type: "string",
  },
  {
    key: "make_and_model",
    name: "make_and_model",
    search_type: "contains",
    type: "string",
  },
  {
    key: "license_plate_number",
    name: "license_plate_number",
    search_type: "contains",
    type: "string",
  },
  {
    key: "reservation_price",
    name: "reservation_price",
    search_type: "equal",
    type: "number",
  },
  {
    key: "adds_products_price",
    name: "adds_products_price",
    search_type: "equal",
    type: "number",
  },
];

interface AdvancedReservationsTableComponentProps {
  messages: any;
}

const AdvancedReservationsTableComponent: React.FC<AdvancedReservationsTableComponentProps> =
  ({ messages }) => {
    const lang = useLang();
    moment.locale(lang === "no" ? "nn" : lang);

    const navigateTo = useNavigate();

    let defaultSearch: any = [];
    let cpy_defaultSearch: any = [];

    declarate_filters.forEach((filter) => {
      const _single_filter: FilterType = {
        key: filter.key,
        name: messages[filter.name ? filter.name : filter.key],
        value: null,
        search_type: filter.search_type,
        type: filter.type,
      };
      defaultSearch.push(_single_filter);
      cpy_defaultSearch.push(_single_filter);
    });

    let challenge: string | null = null;
    const location = useLocation();

    if (location.search) {
      const params = new URLSearchParams(location.search);
      if (params.has("q")) {
        // challenge = decryptObject(params.get("q"));
        challenge = params.get("q");
        const raw_challenge = decryptObject(params.get("q"));
        // console.log("raw_challenge=>", raw_challenge);

        if (raw_challenge && raw_challenge.filter) {
          // console.log("raw_challenge.filter=>", raw_challenge.filter);

          defaultSearch = [...raw_challenge.filter];
        }
      }
    }

    const on_filter = (local_filters: any, clear: boolean = false) => {
      // console.log("local_filters=>", local_filters);

      let selected_challenge = challenge
        ? { ...decryptObject(challenge) }
        : { ...decryptObject(default_challenge) };
      // console.log("selected_challenge=>", selected_challenge);

      if (clear) {
        delete selected_challenge.filters;
      }

      let tmp = {
        ...selected_challenge,
      };

      tmp.filter = [...local_filters];

      const final_challenge = {
        ...tmp,
      };

      // console.log("final_challenge=>", final_challenge);

      navigateTo(
        `/reservations/advanced?q=${encryptObject({ ...final_challenge })}`
      );
    };

    const { data, error, loading } = useQuery(RESERVATIONS_QUERY, {
      variables: { challenge: challenge ? challenge : default_challenge },
    });

    const [filters, set_filter] = React.useState<FilterType[] | null>(
      defaultSearch
    );

    React.useEffect(() => {
      return () => {
        declarate_filters.forEach((filter) => {
          const _single_filter: FilterType = {
            key: filter.key,
            name: messages[filter.name ? filter.name : filter.key],
            value: null,
            search_type: filter.search_type,
            type: filter.type,
          };
          defaultSearch.push(_single_filter);
        });
        set_filter([...cpy_defaultSearch]);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    React.useEffect(() => {
      if (
        data &&
        data.reservations &&
        decryptObject(data.reservations).filter
      ) {
        set_filter(decryptObject(data.reservations).filter);
      }
    }, [data]);

    if (loading) {
      return <NProgressComponent />;
    }

    if (error) {
      return <DisplayErrorLangContainer error={error} />;
    }

    if (data && data.reservations) {
      const reservations = decryptObject(data.reservations).reservations;
      const pages = decryptObject(data.reservations).pages;
      // console.log("data=>", decryptObject(data.reservations));

      return (
        <Flex w="100%" direction="column">
          {/* filters: <pre>{JSON.stringify(filters, null, 2)}</pre> */}
          <Flex>
            <RippleButton
              onClick={() => navigateTo("/reservations")}
              pr="16px"
              variant="unstyled"
              rounded="0"
              color="brand.500"
            >
              <Flex pr="16px">
                <Icon as={MdManageSearch} w="6" h="6" mr="4px" />{" "}
                <span>{messages["simple_search"].toUpperCase()}</span>
              </Flex>
            </RippleButton>
          </Flex>
          <FilterComponent
            filters={filters}
            on_filter={on_filter}
            defaultSearch={defaultSearch}
            row_height={48}
            dropdownTop={`258px`}
            filterModalInputTop={"15vh"}
            filterModalInputLeft={"15vw"}
          />
          {(!reservations || !pages) && (
            <NoContentIllustration
              hint={capitalizeFirstLetter(messages["try_to_clear_filter"])}
            />
          )}
          {reservations && pages > 0 && (
            <>
              <Table w="100%" variant="striped" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th fontSize="16px">{messages["ticket"]}</Th>
                    <Th fontSize="16px">{messages["full_name"]}</Th>
                    <Th fontSize="16px">{messages["email"]}</Th>
                    <Th fontSize="16px">{messages["full_phone_number"]}</Th>
                    <Th fontSize="16px">{messages["createdAt"]}</Th>
                    <Th fontSize="16px">{messages["updatedAt"]}</Th>
                    <Th fontSize="16px">{messages["start_date"]}</Th>
                    <Th fontSize="16px">{messages["end_date"]}</Th>
                    <Th fontSize="16px">{messages["total_price"] + "(€)"}</Th>
                    <Th fontSize="16px">{messages["status"]}</Th>
                    <Th fontSize="16px">{messages["number_of_people"]}</Th>
                    <Th fontSize="16px">{messages["days_in_parking"]}</Th>
                    <Th fontSize="16px">
                      {messages["flight_number_at_start"]}
                    </Th>
                    <Th fontSize="16px">{messages["flight_number_at_end"]}</Th>
                    <Th fontSize="16px">{messages["travel_for"]}</Th>
                    <Th fontSize="16px">{messages["langue"]}</Th>
                    <Th fontSize="16px">{messages["country"]}</Th>
                    <Th fontSize="16px">{messages["pure_phone_number"]}</Th>
                    <Th fontSize="16px">
                      {messages["code_country_phone_number"]}
                    </Th>
                    <Th fontSize="16px">
                      {messages["code_plus_name_country_phone_number"]}
                    </Th>
                    <Th fontSize="16px">{messages["company_name"]}</Th>
                    <Th fontSize="16px">{messages["address"]}</Th>
                    <Th fontSize="16px">{messages["address_two"]}</Th>
                    <Th fontSize="16px">{messages["zip_code"]}</Th>
                    <Th fontSize="16px">{messages["city"]}</Th>
                    <Th fontSize="16px">{messages["business_country"]}</Th>
                    <Th fontSize="16px">{messages["vat_number"]}</Th>
                    <Th fontSize="16px">{messages["make_and_model"]}</Th>
                    <Th fontSize="16px">{messages["license_plate_number"]}</Th>
                    <Th fontSize="16px">{messages["prices"]}</Th>
                    <Th fontSize="16px">
                      {messages["reservation_price"] + "(€)"}
                    </Th>
                    <Th fontSize="16px">{messages["products"]}</Th>
                    <Th fontSize="16px">
                      {messages["adds_products_price"] + "(€)"}
                    </Th>
                    <Th fontSize="16px">{messages["action"]}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {reservations.map((reservationItem: any, i: any) => (
                    <Tr key={i}>
                      <Td>{reservationItem.num_ticket}</Td>
                      <Td>{reservationItem.full_name}</Td>
                      <Td>{reservationItem.email}</Td>
                      <Td>{reservationItem.full_phone_number}</Td>
                      <Td minW="200px">
                        {moment(reservationItem.createdAt).locale("en").format(
                          "dddd DD MMM YYYY - HH:mm"
                        )}
                      </Td>
                      <Td minW="200px">
                        {reservationItem.updatedAt !==
                        reservationItem.createdAt ? (
                          moment(reservationItem.updatedAt).locale("en").format(
                            "dddd DD MMM YYYY - HH:mm"
                          )
                        ) : (
                          <Flex alignItems="center" justifyContent="center">
                            <Icon
                              color="gray"
                              as={MdNotInterested}
                              w="6"
                              h="6"
                              p="8pw"
                            />
                          </Flex>
                        )}
                      </Td>
                      <Td minW="200px">
                        {moment(
                          reservationItem.start_date_int,
                          "YYYYMMDD"
                        ).locale("en").format("dddd DD MMM YYYY")}{" "}
                        {reservationItem.start_time}
                      </Td>
                      <Td minW="200px">
                        {moment(
                          reservationItem.end_date_int,
                          "YYYYMMDD"
                        ).locale("en").format("dddd DD MMM YYYY")}{" "}
                        {reservationItem.end_time}
                      </Td>
                      <Td>
                        <Badge px="15px" variant="solid" colorScheme="green">
                          {reservationItem.total_price} €
                        </Badge>
                      </Td>
                      <Td>
                        <GetColorForStatus
                          status={reservationItem.status}
                          name={messages[reservationItem.status]}
                        />
                      </Td>
                      <Td>
                        <Flex alignItems="center" justifyContent="center">
                          <Flex pr="8px">
                            {reservationItem.number_of_people}
                          </Flex>
                          <Icon
                            color="gray.800"
                            as={MdOutlinePeople}
                            w="6"
                            h="6"
                            p="8pw"
                          />
                        </Flex>
                      </Td>
                      <Td>
                        <Flex alignItems="center" justifyContent="center">
                          <Flex pr="8px">
                            {reservationItem.days_in_parking}
                          </Flex>
                          <Icon
                            color="gray.800"
                            as={MdOutlineCalendarToday}
                            w="6"
                            h="6"
                            p="8pw"
                          />
                        </Flex>
                      </Td>
                      <Td>
                        {reservationItem.flight_number_at_start && (
                          <Badge colorScheme="telegram">
                            <Flex alignItems="center" justifyContent="center">
                              <Flex>
                                <Icon
                                  color="gray.800"
                                  as={MdOutlineAirplaneTicket}
                                  w="6"
                                  h="6"
                                  p="8pw"
                                />
                                &nbsp;&nbsp;
                                <Flex pt="2px">
                                  {reservationItem.flight_number_at_start}
                                </Flex>
                              </Flex>
                            </Flex>
                          </Badge>
                        )}
                        {!reservationItem.flight_number_at_start && (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Badge colorScheme="telegram">
                              <Icon
                                color="gray.800"
                                as={MdAirplanemodeInactive}
                                w="6"
                                h="6"
                                p="8pw"
                              />
                            </Badge>
                          </Flex>
                        )}
                      </Td>

                      <Td>
                        {reservationItem.flight_number_at_end && (
                          <Badge colorScheme="whatsapp">
                            <Flex alignItems="center" justifyContent="center">
                              <Flex>
                                <Icon
                                  color="gray.800"
                                  as={MdAirplaneTicket}
                                  w="6"
                                  h="6"
                                  p="8pw"
                                />
                                &nbsp;&nbsp;
                                <Flex pt="2px">
                                  {reservationItem.flight_number_at_end}
                                </Flex>
                              </Flex>
                            </Flex>
                          </Badge>
                        )}
                        {!reservationItem.flight_number_at_end && (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Badge colorScheme="whatsapp">
                              <Icon
                                color="gray.800"
                                as={MdOutlineAirplanemodeInactive}
                                w="6"
                                h="6"
                                p="8pw"
                              />
                            </Badge>
                          </Flex>
                        )}
                      </Td>

                      <Td>{messages[reservationItem.travel_for]}</Td>
                      <Td>{getLangByCode(reservationItem.code_lang)}</Td>
                      <Td>
                        <GetTranslatedCountry code={reservationItem.country} />
                      </Td>
                      <Td>{reservationItem.pure_phone_number}</Td>
                      <Td>
                        <GetDialCodeByCountryCode
                          code={reservationItem.code_country_phone_number}
                        />
                      </Td>
                      <Td>
                        <GetDialCodeByCountryCode
                          code={reservationItem.code_country_phone_number}
                        />{" "}
                        <GetTranslatedCountry
                          code={reservationItem.code_country_phone_number}
                        />
                      </Td>
                      <Td>
                        {reservationItem.company_name}
                        {!reservationItem.company_name && (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon
                              color="gray"
                              as={MdNotInterested}
                              w="6"
                              h="6"
                              p="8pw"
                            />
                          </Flex>
                        )}
                      </Td>
                      <Td>
                        {reservationItem.address}
                        {!reservationItem.address && (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon
                              color="gray"
                              as={MdNotInterested}
                              w="6"
                              h="6"
                              p="8pw"
                            />
                          </Flex>
                        )}
                      </Td>
                      <Td>
                        {reservationItem.address_two}
                        {!reservationItem.address_two && (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon
                              color="gray"
                              as={MdNotInterested}
                              w="6"
                              h="6"
                              p="8pw"
                            />
                          </Flex>
                        )}
                      </Td>
                      <Td>
                        {reservationItem.zip_code}
                        {!reservationItem.zip_code && (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon
                              color="gray"
                              as={MdNotInterested}
                              w="6"
                              h="6"
                              p="8pw"
                            />
                          </Flex>
                        )}
                      </Td>
                      <Td>
                        {reservationItem.city}
                        {!reservationItem.city && (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon
                              color="gray"
                              as={MdNotInterested}
                              w="6"
                              h="6"
                              p="8pw"
                            />
                          </Flex>
                        )}
                      </Td>
                      <Td>
                        {reservationItem.business_country && (
                          <GetTranslatedCountry
                            code={reservationItem.business_country}
                          />
                        )}

                        {!reservationItem.business_country && (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon
                              color="gray"
                              as={MdNotInterested}
                              w="6"
                              h="6"
                              p="8pw"
                            />
                          </Flex>
                        )}
                      </Td>
                      <Td>
                        {reservationItem.vat_number}
                        {!reservationItem.vat_number && (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon
                              color="gray"
                              as={MdNotInterested}
                              w="6"
                              h="6"
                              p="8pw"
                            />
                          </Flex>
                        )}
                      </Td>
                      <Td>
                        {reservationItem.make_and_model}
                        {!reservationItem.make_and_model && (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon
                              color="gray"
                              as={MdNotInterested}
                              w="6"
                              h="6"
                              p="8pw"
                            />
                          </Flex>
                        )}
                      </Td>
                      <Td>
                        {reservationItem.license_plate_number}
                        {!reservationItem.license_plate_number && (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon
                              color="gray"
                              as={MdNotInterested}
                              w="6"
                              h="6"
                              p="8pw"
                            />
                          </Flex>
                        )}
                      </Td>
                      <Td>
                        <PriceContainerByID
                          challenge={encryptObject({
                            id: reservationItem.id_price,
                          })}
                        />
                      </Td>
                      <Td>
                        <Badge px="15px" variant="solid" colorScheme="orange">
                          {reservationItem.reservation_price} €
                        </Badge>
                      </Td>
                      <Td>
                        {reservationItem.id_products.length > 2 && (
                          <ProductsModalPreviewContainer
                            challenge={encryptObject({
                              array_IDS: JSON.parse(
                                reservationItem.id_products
                              ),
                              number_of_people:
                                reservationItem.number_of_people,
                            })}
                          />
                        )}
                        {reservationItem.id_products.length < 3 && (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon
                              color="gray"
                              as={MdNotInterested}
                              w="6"
                              h="6"
                              p="8pw"
                            />
                          </Flex>
                        )}
                      </Td>
                      <Td>
                        {reservationItem.adds_products_price !== 0 && (
                          <Badge
                            px="15px"
                            variant="solid"
                            colorScheme="facebook"
                          >
                            {reservationItem.adds_products_price} €
                          </Badge>
                        )}

                        {reservationItem.adds_products_price === 0 && (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon
                              color="gray"
                              as={MdNotInterested}
                              w="6"
                              h="6"
                              p="8pw"
                            />
                          </Flex>
                        )}
                      </Td>

                      <Td>
                        <Flex alignItems="center" justifyContent="center">
                          <Button
                            as={Link}
                            p="0"
                            boxShadow="0"
                            _focus={{ boxShadow: "0" }}
                            variant="ghost"
                            rounded="full"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${config.BEP}/a/${encryptObject({
                              action: "print",
                              id: reservationItem.id,
                            })}?lang=${config.DEFAULT_LANG}&manageR=true`}
                          >
                            <Icon as={MdPrint} w="6" h="6" p="8pw" />
                          </Button>
                          <Button
                            as={Link}
                            p="0"
                            boxShadow="0"
                            _focus={{ boxShadow: "0" }}
                            variant="ghost"
                            rounded="full"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${config.BEP}/a/${encryptObject({
                              action: "edit",
                              id: reservationItem.id,
                            })}?lang=${config.DEFAULT_LANG}&manageR=true`}
                          >
                            <Icon as={MdEdit} w="6" h="6" p="8pw" />
                          </Button>

                          <Button
                            as={Link}
                            p="0"
                            boxShadow="0"
                            _focus={{ boxShadow: "0" }}
                            variant="ghost"
                            rounded="full"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${config.BEP}/a/${encryptObject({
                              action: "cancel",
                              id: reservationItem.id,
                            })}?lang=${config.DEFAULT_LANG}&manageR=true`}
                          >
                            <Icon as={MdCancel} w="6" h="6" p="8pw" />
                          </Button>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <PaginationComponent
                entity="reservations"
                messages={messages}
                pages={pages}
              />
            </>
          )}
        </Flex>
      );
    } else {
      return <div>err</div>;
    }
  };

export default AdvancedReservationsTableComponent;
