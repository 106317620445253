export const getValueOfItemBykey = (key: string, arrayOfObject: any) => {
  return arrayOfObject.filter((el: any) => el.key === key)[0].value;
};

export const getAttrOfItemBykey = (key: string, arrayOfObject: any, attr: any) => {
  return arrayOfObject.filter((el: any) => el.key === key)[0][attr];
};

export const updateObject = (
  itemToUpdate: any,
  arrayOfObject: any,
  identifier: string = "key"
) => {
  // console.log("xxx arrayOfObject => ", arrayOfObject);
  // console.log("xxx itemToUpdate => ", itemToUpdate);
  // console.log("xxx itemToUpdate[identifier] => ", itemToUpdate[identifier]);

  // get current obj
  let _selected_item = arrayOfObject.find(
    (el: any) => el[identifier] === itemToUpdate[identifier]
  );
  // console.log("xxx _selected_item => ", _selected_item);

  let index_of_selected_item = arrayOfObject.indexOf(_selected_item);

  // console.log("xxx index_of_selected_item => ", index_of_selected_item);

  let copy_of_array_of_object = [...arrayOfObject];

  copy_of_array_of_object[index_of_selected_item] = itemToUpdate;

  return copy_of_array_of_object;
};
