import { Button } from "@chakra-ui/button";
import { useColorMode, useColorModeValue } from "@chakra-ui/color-mode";
import { Icon } from "@chakra-ui/icons";
import { InputGroup, InputLeftElement, Input } from "@chakra-ui/input";
import { Box, Flex, Heading, Spacer, Stack, Text } from "@chakra-ui/layout";

import { MdOutlineAlternateEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaSignInAlt } from "react-icons/fa";

import React from "react";
import { ColorModeSwitcher } from "../theme/ColorModeSwitcher";
import { Formik } from "formik";

import { useMutation } from "@apollo/client";
import { CURRENT_USER_QUERY, SIGNIN_MUTATION } from "../Apollo/typedefs";
import { decryptObject, encryptObject } from "../utils/crypt";

import DisplayErrorLangContainer from "../Components/DisplayError/containers/lang";
import { capitalizeFirstLetter } from "../utils/string";
import { config } from "../config";

interface LoginPageProps {
  messages: any;
}

const LoginPage: React.FC<LoginPageProps> = ({ messages }) => {
  const { colorMode } = useColorMode();

  const bg = useColorModeValue("gray.200", "gray.900");

  const [signIn, { loading, error }] = useMutation(SIGNIN_MUTATION, {
    refetchQueries: [CURRENT_USER_QUERY],
  });

  if (error && error.message) {
    const decrypted_error = JSON.parse(decryptObject(error.message));

    const { x_rate_limit_remaining } = decrypted_error;

    if (x_rate_limit_remaining === 0) {
      localStorage.setItem(
        "ga__54684565",
        "411" + btoa(new Date().toString()) + "254"
      );
      localStorage.setItem("ga_465489", btoa(new Date().toString()));

      window.location.reload();
    }
  }

  return (
    <Flex alignItems="center" justifyContent="center" height="100vh" bg={bg}>
      <Box w="md" h="md">
        <Flex px="100" pb="4" alignItems="center">
          <img
            src={`/assets/images/logo_${
              colorMode !== "dark" ? "dark" : "light"
            }.png`}
            alt={capitalizeFirstLetter(config.APP_NAME)}
          />
        </Flex>

        <Flex px="3" alignItems="center">
          <Heading size="sm">
            {capitalizeFirstLetter(
              messages["sign_in_and_start_managing_your_reservations"]
            )}
          </Heading>
          <Spacer />
          <ColorModeSwitcher />
        </Flex>

        <Stack pt="3">
          {error && (
            <DisplayErrorLangContainer error={error} type="login-errors" />
          )}
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              const errors: any = {};
              if (!values.email) {
                errors.email = capitalizeFirstLetter(messages["required"]);
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = capitalizeFirstLetter(
                  messages["invalid_email_address"]
                );
              }

              if (!values.password) {
                errors.password = capitalizeFirstLetter(messages["required"]);
              } else if (
                values.password.length >= 64 ||
                values.password.length < 6
              ) {
                errors.password = capitalizeFirstLetter(
                  messages["invalid_password"]
                );
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const { email, password } = values;
              if (!loading && signIn !== null) {
                await signIn({
                  variables: { challenge: encryptObject({ email, password }) },
                });
              }
              // console.log(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                <Stack
                  spacing="5"
                  p="3"
                  w="100%"
                  boxShadow="md"
                  rounded="6"
                  bg="gray.500"
                >
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={
                        <Icon as={MdOutlineAlternateEmail} color="gray.300" />
                      }
                    />
                    <Input
                      type="email"
                      name="email"
                      disabled={isSubmitting}
                      autoComplete="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder={capitalizeFirstLetter(messages["email"])}
                    />
                  </InputGroup>

                  {errors.email && touched.email && errors.email && (
                    <Text size="sm" color="red.500">
                      {errors.email}
                    </Text>
                  )}

                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={
                        <Icon as={RiLockPasswordFill} color="gray.300" />
                      }
                    />
                    <Input
                      type="password"
                      name="password"
                      disabled={isSubmitting}
                      autoComplete="current-password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      placeholder={capitalizeFirstLetter(messages["password"])}
                    />
                  </InputGroup>
                  {errors.password && touched.password && errors.password && (
                    <Text size="sm" color="red.500">
                      {errors.password}
                    </Text>
                  )}

                  <InputGroup>
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      isFullWidth
                      leftIcon={<Icon as={FaSignInAlt} fontSize="16" />}
                      colorScheme="brand"
                      variant="solid"
                    >
                      {capitalizeFirstLetter(messages["login"])}
                    </Button>
                  </InputGroup>
                </Stack>
              </form>
            )}
          </Formik>
        </Stack>
      </Box>
    </Flex>
  );
};

export default LoginPage;
