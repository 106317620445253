/**
 * asyncForEach.
 * @param {array} array Original array.
 * @param {function} callback Async array.
 */
const asyncForEach = async (array, callback) => {
  try {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  } catch (error) {
    // console.log(error);
    //   await transport.sendMail({
    //     from: "BUG Alert from Syncro Park  <" + process.env.GMAIL_USER + ">",
    //     to: process.env.MAIL_DEV,
    //     subject: `BUG Alert #${new Date().getTime()}`,
    //     html: SendMailToDev(`
    //           Error on fonction => asyncForEach(array: ${JSON.stringify(
    //       array
    //     )}, callback: ${JSON.stringify(callback)}) : \n
    //           ${error}
    //           `),
    //   });
    // await console.log("mail sent to DEV! 8");
  }
};

export default asyncForEach;
