import { Box } from "@chakra-ui/layout";
import React from "react";
import NavbarLangContainer from "./Navbar/containers/lang";

interface LayoutComponentProps {}

const LayoutComponent: React.FC<LayoutComponentProps> = ({ children }) => {
  return (
    <Box>
      <NavbarLangContainer />
      <Box id="main-content">{children}</Box>
    </Box>
  );
};

export default LayoutComponent;
