import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Spacer,
  Input,
  Flex,
  Box,
  ModalFooter,
  useToast,
  Progress,
} from "@chakra-ui/react";

import { useDisclosure } from "@chakra-ui/hooks";

import React from "react";
import { useMutation } from "@apollo/client";
import {
  CREATE_PRODUCT,
  PRODUCTS_QUERY,
  UPDATE_PRODUCT,
} from "../../Apollo/typedefs";
import { decryptObject, encryptObject } from "../../utils/crypt";
import RippleButton from "../../Components/RippleButton";
import { capitalizeFirstLetter } from "../../utils/string";
import { lang_list } from "../../lang/lang_list";
import useLang from "../../hooks/useLang";
import translate from "../../lang/translate";
import asyncForEach from "../../utils/asyncForeach";
import sleep from "../../utils/sleep";

export type productRequestType = {
  id?: string | null;
  name: string;
  price: string;
};

interface FormComponentProps {
  messages: any;
  type: "create" | "update";
  data?: productRequestType | null;
  cleanup?: () => void | null;
}

const FormComponent: React.FC<FormComponentProps> = ({
  messages,
  type,
  children,
  data,
  cleanup,
}) => {
  const lang = useLang();
  const [mutationFunction, { loading }] = useMutation(
    type === "create" ? CREATE_PRODUCT : UPDATE_PRODUCT,

    {
      refetchQueries: [PRODUCTS_QUERY],
    }
  );

  let mutationName = `${type}Product`;

  let validation = false;

  const toast = useToast();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [id, set_id] = React.useState<string | null>(null);
  const [name, setName] = React.useState<any>({
    nl: "",
    fr: "",
    de: "",
    en: "",
    ar: "",
    es: "",
    it: "",
    da: "",
    hu: "",
    no: "",
    pl: "",
    pt: "",
    fi: "",
    sv: "",
  });
  const [name_touched, set_name_touched] = React.useState(false);

  const [price, setPrice] = React.useState("");
  const [price_touched, set_price_touched] = React.useState(false);

  const [detected_changes, set_detected_changes] = React.useState(true);
  const [progress_state, set_progress_state] = React.useState(0);

  const onCancel = () => {
    onClose();
    cleanup && cleanup();
    setName("");
    setPrice("");
    setTimeout(() => {
      set_name_touched(false);
      set_price_touched(false);
    }, 310);
  };

  const onSave = async () => {
    set_name_touched(true);
    set_price_touched(true);
    try {
      if (!loading && mutationFunction !== null) {
        const productRequest: productRequestType = {
          id,
          name: JSON.stringify(name),
          price,
        };

        const { data } = await mutationFunction({
          variables: {
            challenge: encryptObject({ ...productRequest }),
          },
        });
        if (!data) {
          // console.log("something went wrong 01");
        }
        // console.log(data);

        const { msg } = decryptObject(data[mutationName]);
        if (!msg) {
          // console.log("something went wrong 02");
        }

        if (msg === type + "d") {
          onCancel();

          toast({
            title: messages[`product_${type}d`],
            description: capitalizeFirstLetter(
              messages[`weve_${type}d_your_product_for_you`]
            ),
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const lastProductInputRef = React.useRef<HTMLInputElement>(null);
  const [isEditingOnInput, setIsEditingOnInput] = React.useState(false);

  const [auto_translate_all_is_loading, set_auto_translate_all_is_loading] =
    React.useState(false);

  Object.keys(name).forEach((el: any) => {
    if (name[el].length > 3) {
      validation = true;
    } else {
      validation = false;
    }
  });

  if (price.length > 0) {
    validation = true;
  } else {
    validation = false;
  }

  const handleAutoTranslate = async (currentLang: string) => {
    const translated = await translate(name[lang], lang, currentLang);
    validation = true;
    return translated;
  };

  const handleAutoTranslateAll = async () => {
    set_auto_translate_all_is_loading(true);
    let newNames = { ...name };

    try {
      await asyncForEach(lang_list, async (el: any) => {
        const translated = await translate(name[lang], lang, el.code);
        newNames[el.code] = translated;
        await sleep(500);
        set_progress_state((prev) => prev + 100 / 13);
      });
    } catch (error) {
      set_auto_translate_all_is_loading(false);
      validation = false;
    }
    validation = true;

    set_auto_translate_all_is_loading(false);

    return newNames;
  };

  React.useEffect(() => {
    if (lastProductInputRef.current !== null && isEditingOnInput) {
      lastProductInputRef.current.focus();
    }
    return () => {
      setIsEditingOnInput(false);
    };
  }, [isEditingOnInput]);

  React.useEffect(() => {
    if (type === "update" && data) {
      onOpen();

      setName(JSON.parse(data.name));
      setPrice(data.price);

      if (typeof data.id === "string") {
        set_id(data.id || null);
      }
    }
    return () => {};
  }, [onOpen, type, data]);

  React.useEffect(() => {
    set_detected_changes(true);
  }, [name, price]);

  return (
    <>
      <Flex onClick={() => onOpen()}>{children}</Flex>
      <Modal
        size="2xl"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onCancel}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {capitalizeFirstLetter(messages[`${type}_product`])}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl pb="16px" alignItems="center">
              <Flex direction="column">
                {progress_state < 100 && name[lang] && name[lang].length > 3 && (
                  <Box my="15px">
                    <Progress
                      colorScheme="green"
                      size="lg"
                      value={progress_state}
                    />
                  </Box>
                )}
                <Flex
                  bg="gray.50"
                  p="4px"
                  borderRadius="6px"
                  borderWidth="2.75px"
                  borderColor="brand.500"
                  mb="8px"
                >
                  <Flex w="100%" direction="column">
                    <FormLabel minW="64px">
                      {capitalizeFirstLetter(messages["name"])} (
                      <b>
                        {lang_list.filter((el) => el.code === lang)[0].label}
                      </b>
                      )
                      {name[lang] && name[lang].length > 3 && (
                        <RippleButton
                          isLoading={auto_translate_all_is_loading}
                          colorScheme="green"
                          variant="outline"
                          onClick={async () => {
                            const newText = await handleAutoTranslateAll();
                            await setName(newText);
                            await set_progress_state(0);
                          }}
                          title="click here to get a translation from Google translate"
                          h="32px"
                          ml="16px"
                        >
                          Auto translate ALL
                        </RippleButton>
                      )}
                    </FormLabel>
                    <Input
                      readOnly={auto_translate_all_is_loading}
                      w="100%"
                      onBlur={() => {
                        setTimeout(() => {
                          set_name_touched(true);
                        }, 300);
                      }}
                      onChange={(e) =>
                        setName((prev: any) => {
                          let tmp = { ...prev };
                          tmp[lang] = e.target.value;
                          return tmp;
                        })
                      }
                      value={name[lang]}
                      ref={initialRef}
                      placeholder={`${messages["name"]}...`}
                    />
                  </Flex>
                </Flex>
                {lang_list
                  .filter((el) => el.code !== lang)
                  .map((el) => (
                    <Flex key={`unique-${el.code}`} mb="8px">
                      <Flex w="100%" direction="column">
                        <FormLabel minW="64px">
                          {capitalizeFirstLetter(messages["name"])} (
                          <b>{el.label}</b>)
                          {name[lang] && name[lang].length > 3 && (
                            <RippleButton
                              isLoading={auto_translate_all_is_loading}
                              colorScheme="green"
                              variant="outline"
                              onClick={async () => {
                                const newText = await handleAutoTranslate(
                                  el.code
                                );
                                let _name = { ...name };
                                _name[el.code] = newText;
                                await setName(_name);
                              }}
                              title="click here to get a translation from Google translate"
                              h="32px"
                              ml="16px"
                            >
                              Auto translate
                            </RippleButton>
                          )}
                          {name[lang] && name[lang].length > 3 && (
                            <RippleButton
                              isLoading={auto_translate_all_is_loading}
                              onClick={() =>
                                window.open(
                                  `https://translate.google.com/?sl=${lang}&tl=${
                                    el.code
                                  }&text=${encodeURI(name[lang])}&op=translate`
                                )
                              }
                              title="click here to get a translation from Google translate"
                              h="32px"
                              ml="16px"
                            >
                              Manuel translate
                            </RippleButton>
                          )}
                        </FormLabel>
                        <Input
                          readOnly={auto_translate_all_is_loading}
                          w="100%"
                          onBlur={() => {
                            setTimeout(() => {
                              set_name_touched(true);
                            }, 300);
                          }}
                          onChange={(e) =>
                            setName((prev: any) => {
                              let tmp = { ...prev };
                              tmp[el.code] = e.target.value;
                              return tmp;
                            })
                          }
                          value={name[el.code]}
                          placeholder={`${messages["name"]}...`}
                        />
                      </Flex>
                    </Flex>
                  ))}
              </Flex>

              {name_touched && (
                <Flex pt="8px" color="red">
                  {name.length < 3 &&
                    capitalizeFirstLetter(
                      messages["please_provide_a_name_at_least_3_caracters"]
                    )}
                </Flex>
              )}
            </FormControl>

            <FormControl pb="16px" alignItems="center">
              <Flex>
                <FormLabel minW="64px">
                  {capitalizeFirstLetter(messages["price"])}
                </FormLabel>
                <Spacer />
                <Input
                  onWheel={() => {
                    (document.activeElement as HTMLElement).blur();
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      set_price_touched(true);
                    }, 300);
                  }}
                  onChange={(e) => setPrice(e.target.value)}
                  value={price}
                  type="number"
                  placeholder={`${capitalizeFirstLetter(messages["price"])}...`}
                />
              </Flex>
              {price_touched && (
                <Flex pt="8px" color="red">
                  {price.length < 1 &&
                    capitalizeFirstLetter(messages["please_provide_a_price"])}
                </Flex>
              )}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <RippleButton
              variant="unstyled"
              color="brand.500"
              disabled={type === "create" ? !validation : !detected_changes}
              onClick={onSave}
              isLoading={loading || auto_translate_all_is_loading}
              colorScheme="brand"
              mr={3}
            >
              <Flex px="16px">{capitalizeFirstLetter(messages["save"])}</Flex>
            </RippleButton>
            <RippleButton color="black" variant="unstyled" onClick={onCancel}>
              <Flex px="16px">{capitalizeFirstLetter(messages["cancel"])}</Flex>
            </RippleButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FormComponent;
