import React from "react";
import Calendar from "../../../Components/availability-calendar/src/index";
import "./Calendar.css";
import moment from "moment";
import { updateRequest } from "../index";

const getIntFromDate = (date: Date) => +moment(date).locale("en").format("YYYYMMDD");

interface CalendarComponentProps {
  disabled_dates: updateRequest;
  set_disabled_dates: React.Dispatch<React.SetStateAction<updateRequest>>;
  locale: string;
}

const CalendarComponent: React.FC<CalendarComponentProps> = ({
  disabled_dates,
  set_disabled_dates,
  locale,
}) => {
  const [value, onChange] = React.useState(new Date());

  return (
    <Calendar
      locale={locale}
      next2Label={null}
      prev2Label={null}
      minDate={new Date()}
      onChange={onChange}
      value={value}
      onClickDay={(e: Date) => {
         console.log("clicked");

        let tmp = { ...disabled_dates };
         console.log("tmp=>", tmp);

        const date = getIntFromDate(e);
         console.log("date=>", date);

        if (disabled_dates.blocked_dates.includes(date)) {
           console.log(date + " exist in " + disabled_dates.blocked_dates);
          const filtred = tmp.blocked_dates.filter((el) => el !== date);
           console.log("filtred=>", filtred);
          tmp.blocked_dates = [...filtred];
          tmp.unblocked_dates = [date, ...tmp.unblocked_dates];
        } else {
          if (date >= getIntFromDate(new Date())) {
             console.log("else");
            tmp.blocked_dates.push(date);
            tmp.new_blocked_dates.push(date);

             console.log("tmp after else =>", tmp);
          } else {
             console.log("no");
          }
        }

        set_disabled_dates({ ...tmp, touched: true });
      }}
      tileDisabled={(p: any) => {
        const date = getIntFromDate(p.date);

        if (disabled_dates.blocked_dates.includes(date)) {
          return true;
        } else {
          return false;
        }
      }}
    />
  );
};

export default CalendarComponent;
