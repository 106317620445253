import { useQuery } from "@apollo/client";
import React from "react";
import ProductsModalPreview from ".";
import { GET_PRODUCTS_BY_ARRAY_IDS } from "../../Apollo/typedefs";
import useMessagesLocalStorage from "../../hooks/useMessagesLocalStorage";
import { decryptObject } from "../../utils/crypt";

interface ProductsModalPreviewContainerProps {
  challenge: any;
}

const ProductsModalPreviewContainer: React.FC<ProductsModalPreviewContainerProps> =
  ({ challenge }) => {
    const { data, error, loading } = useQuery(GET_PRODUCTS_BY_ARRAY_IDS, {
      variables: { challenge },
    });

    const messages = useMessagesLocalStorage(
      `/lang/components/products_modal_preview/`
    );

    if (loading) {
      return <span>Loading...</span>;
    }

    if (error) {
      return <span>error</span>;
    }

    if (data && data.getProductsByArrayIds) {
      const { products } = decryptObject(data.getProductsByArrayIds);

      if (!products || messages.length < 1) {
        return <span></span>;
      } else {
        return (
          <ProductsModalPreview
            messages={JSON.parse(messages)}
            products={products}
          />
        );
      }
    } else {
      return <span>error 0</span>;
    }
  };

export default ProductsModalPreviewContainer;
