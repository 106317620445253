import Navbar from "..";
import useMessagesLocalStorage from "../../../../hooks/useMessagesLocalStorage";

const NavbarLangContainer = () => {
    const messages = useMessagesLocalStorage(`/lang/layout/navbar/`);
  
    if (messages === "") {
      return <div>loarding...</div>;
    } else {
      return <Navbar messages={JSON.parse(messages)} />;
    }
  };
  
  export default NavbarLangContainer;