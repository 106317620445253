import styled from "styled-components";

export const PaginationContanier = styled.div`
  position: sticky;
  margin-left: auto;
  padding-right: 24 px;
  right: 0;
  width: 600 px;
  width: max-content;
  transform: translateZ(0);

  letter-spacing: 0.011em;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 16px;
`;
