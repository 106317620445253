import { Box, Flex } from "@chakra-ui/layout";
import React from "react";
import ChartSalesByTime from "../Components/Charts/ChartSalesByTime";

const IndexPage: React.FC = () => {
  return (
    <Box bg="gray.100" p="16px">
      <Flex>
        <ChartSalesByTime type="all" />
        <ChartSalesByTime type="last_6_months" />
      </Flex>
    </Box>
  );
};

export default IndexPage;
