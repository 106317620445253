import React from "react";
import { Box, Flex, Spacer } from "@chakra-ui/layout";
import {
  Avatar,
  Icon,
  useColorMode,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { MdMenu, MdHelp, MdLogout, MdSettings } from "react-icons/md";

import {
  CURRENT_USER_QUERY,
  SIGN_OUT_MUTATION,
} from "../../../Apollo/typedefs";

import { getMenu } from "./menu";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { config } from "../../../config";
import { capitalizeFirstLetter } from "../../../utils/string";
import { vh } from "../../../utils/css";

const normal_right_navbar_width = 72 + 16;
const opened_right_navbar_width = 240 + 16;

const normal_topbar_height = 6.45;
const normal_topbar_min_height = 54;

const getRightMarginTop = () => {
  if (vh(normal_topbar_height) > normal_topbar_min_height) {
    return `${normal_topbar_height}vh`;
  } else {
    return `${normal_topbar_min_height}px`;
  }
};

interface NavbarProps {
  messages: any;
}

const Navbar: React.FC<NavbarProps> = ({ messages }) => {
  const { colorMode } = useColorMode();

  const bow = colorMode === "dark" ? "black" : "white";

  const location = useLocation();

  const isActive = (href: string) => {
    if (location.pathname === href) {
      return true;
    }

    // For sub nav
    if (location.pathname.includes(href + "/")) {
      return true;
    }
    return false;
  };

  const [navbar_width, set_navbar_width] = React.useState(
    normal_right_navbar_width
  );
  const [is_navbar_closed, set_is_navbar_closed] = React.useState(true);

  const toggleNavbar = () => {
    set_is_navbar_closed(!is_navbar_closed);
    set_navbar_width(
      is_navbar_closed ? opened_right_navbar_width : normal_right_navbar_width
    );
  };

  const [isHelpDialogOpen, setIsHelpDialogOpen] = React.useState(false);
  const onClose = () => setIsHelpDialogOpen(false);
  const cancelRef = React.useRef(null);

  const [is_top_toolbar_menu_closed, set_is_top_toolbar_menu_closed] =
    React.useState(true);

  const [logoutUser] = useMutation(SIGN_OUT_MUTATION, {
    refetchQueries: [CURRENT_USER_QUERY],
  });

  React.useEffect(() => {
    const mainContentElement = document.getElementById("main-content");
    if (mainContentElement) {
      mainContentElement.style.marginLeft = `${navbar_width}px`;
      mainContentElement.style.marginTop = getRightMarginTop();
    }
  }, [navbar_width]);

  return (
    <Box bg="white" zIndex="100" w="100%" position="fixed" top="0">
      <Flex boxShadow="md" minH={`54px`} h={getRightMarginTop()}>
        <Flex alignItems="center" flex="1" px="16px">
          <Flex>
            <Flex px="19px">
              <Icon
                onClick={() => toggleNavbar()}
                cursor="pointer"
                w={6}
                h={6}
                as={MdMenu}
              />
            </Flex>
            <Flex display={{ base: "none", sm: "flex" }} ml="8px">
              <a target="_blank" rel="noreferrer" href={config.APP_URL}>
                <img
                  style={{ height: "24px" }}
                  src={`/assets/images/logo_${
                    colorMode !== "dark" ? "dark" : "light"
                  }.png`}
                  alt={capitalizeFirstLetter(config.APP_NAME)}
                />
              </a>
            </Flex>
            <Flex display={{ base: "flex", sm: "none" }} ml="8px">
              <a target="_blank" rel="noreferrer" href={config.APP_URL}>
                <img
                  style={{ height: "24px" }}
                  src={`/assets/images/logo_only_${
                    colorMode !== "dark" ? "dark" : "light"
                  }.png`}
                  alt={capitalizeFirstLetter(config.APP_NAME)}
                />
              </a>
            </Flex>
          </Flex>
          <Spacer />
          {/* <Flex padding="1px 16px">
            <ColorModeSwitcher />
          </Flex> */}

          <Flex padding="1px 16px">
            <Icon
              onClick={() => setIsHelpDialogOpen(true)}
              cursor="pointer"
              color="gray.900"
              w="6"
              h="6"
              as={MdHelp}
              _hover={{ color: "gray.700" }}
            />
          </Flex>
          <Flex padding="1px 6px">
            <Avatar
              cursor="pointer"
              onClick={() =>
                set_is_top_toolbar_menu_closed(!is_top_toolbar_menu_closed)
              }
              size="sm"
              name="Aero Park"
            />
            {/* START Dropdown top toolbar menu */}
            {!is_top_toolbar_menu_closed && (
              <Box>
                <Flex
                  bg="white"
                  zIndex="201"
                  direction="column"
                  borderColor="gray.200"
                  borderWidth=".1px"
                  borderStyle="solid"
                  borderTop="none"
                  width="300px"
                  right="10px"
                  top="49px"
                  position="absolute"
                >
                  <Flex
                    borderBottomColor="gray.200"
                    borderBottomWidth=".1px"
                    borderBottomStyle="solid"
                    alignItems="center"
                    p="16px"
                  >
                    <Avatar mr="16px" size="md" name="Aero Park" />
                    <Text>{capitalizeFirstLetter(config.APP_NAME)}</Text>
                  </Flex>
                  <Flex
                    onClick={() => {
                      set_is_top_toolbar_menu_closed(true);
                      logoutUser();
                    }}
                    cursor="pointer"
                    _hover={{
                      bg: "gray.100",
                    }}
                    alignItems="center"
                    p="16px"
                  >
                    <Icon mr="16px" as={MdLogout} />
                    {messages["signout"]}
                  </Flex>
                </Flex>
                <Box
                  zIndex="200"
                  onClick={() => set_is_top_toolbar_menu_closed(true)}
                  top="0"
                  right="0"
                  bottom="0"
                  left="0"
                  position="fixed"
                  w="100vw"
                  h="100vh"
                ></Box>
              </Box>
            )}

            {/* END Dropdown top toolbar menu */}
          </Flex>
        </Flex>
      </Flex>

      {/* Right navbar */}
      <Box
        id="right-navbar"
        transition="width .325s ease-in-out"
        position="fixed"
        h={`calc(100vh - ${getRightMarginTop()})`}
        w={`${navbar_width}px`}
        bg="white"
        borderRightWidth=".125px"
        borderRightStyle="solid"
        borderRightColor="gray.300"
      >
        <Flex h="100%" direction="column" overflow="auto">
          <Flex
            h="100%"
            direction="column"
            overflow="auto"
            mb={is_navbar_closed ? "90px" : "40px"}
          >
            {getMenu(messages).map((mItem: any) => (
              <Link key={mItem.name} to={mItem.href}>
                <Flex
                  borderLeftWidth="4px"
                  borderLeftColor={isActive(mItem.href) ? "brand.500" : ""}
                  borderLeftStyle="solid"
                  bg={
                    isActive(mItem.href)
                      ? `${bow === "black" ? "white" : "gray.200"}`
                      : "gray.50"
                  }
                  title={mItem.name}
                  alignItems="center"
                  direction={is_navbar_closed ? "column" : "row"}
                  cursor="pointer"
                  transition="all .125s ease-in"
                  pt={is_navbar_closed ? "16px" : "5px"}
                  pb={is_navbar_closed ? "14px" : "5px"}
                  px={is_navbar_closed ? "0px" : "24px"}
                  _hover={{
                    bg: isActive(mItem.href)
                      ? `${bow === "black" ? "white" : "gray.300"}`
                      : "gray.300",
                    borderLeftColor: "brand.600",
                  }}
                >
                  <Icon
                    mr={is_navbar_closed ? "0px" : "24px"}
                    color={isActive(mItem.href) ? "brand.500" : ""}
                    as={mItem.icon}
                    w="6"
                    h="6"
                    mb="6px"
                  />
                  <Text
                    color={isActive(mItem.href) ? "brand.500" : ""}
                    fontWeight={isActive(mItem.href) ? "bold" : "normal"}
                    fontSize={is_navbar_closed ? "10px" : "14px"}
                  >
                    {mItem.name}
                  </Text>
                </Flex>
              </Link>
            ))}
            <Spacer />
          </Flex>
        </Flex>
        <Flex
          position="absolute"
          bottom="0"
          h={is_navbar_closed ? "90px" : "40px"}
          width={`${navbar_width}px`}
          bg="gray.50"
          _hover={{ bg: "gray.300" }}
          cursor="pointer"
          alignItems="center"
          direction="column"
          transition="all .125s ease-in"
          pt={is_navbar_closed ? "16px" : "10px"}
          pb={is_navbar_closed ? "14px" : "36px"}
          px={is_navbar_closed ? "0px" : "24px"}
        >
          <Link to="/settings">
            <Flex
              title={capitalizeFirstLetter(messages["settings"])}
              direction={is_navbar_closed ? "column" : "row"}
              alignItems="center"
            >
              <Icon
                mr={is_navbar_closed ? "0px" : "24px"}
                as={MdSettings}
                w="6"
                h="6"
                mb="6px"
              />
              <Text
                fontWeight={"bold"}
                fontSize={is_navbar_closed ? "10px" : "14px"}
              >
                {capitalizeFirstLetter(messages["settings"])}
              </Text>
            </Flex>
          </Link>
        </Flex>
      </Box>

      <AlertDialog
        isOpen={isHelpDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {capitalizeFirstLetter(messages["help"])}
            </AlertDialogHeader>

            <AlertDialogBody>
              {capitalizeFirstLetter(messages["for_more_help_please_contact"])}{" "}
              <b>
                <a href="mailto:contact@fullbunch.com">contact@fullbunch.com</a>
              </b>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="brand" onClick={onClose} ml={3}>
                {capitalizeFirstLetter(messages["ok"])}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Navbar;
