import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    heading: "Roboto, Noto Sans, sans-serif",
    body: "Roboto, Noto Sans, sans-serif",
  },
  colors: {
    blue: {
      50: "#5AA9FF",
      200: "#48A0FF",
      300: "#3697FF",
      100: "#248DFF",
      400: "#1284FF",
      500: "#007BFF",
      600: "#0068D8",
      700: "#0055B1",
      800: "#004289",
      900: "#002F62",
    },
    brand: {
      50: "#5AA9FF",
      200: "#48A0FF",
      300: "#3697FF",
      100: "#248DFF",
      400: "#1284FF",
      500: "#007BFF",
      600: "#0068D8",
      700: "#0055B1",
      800: "#004289",
      900: "#002F62",
    },
  },
  config: { initialColorMode: "light" },
});
