import SettingsPage from "..";
import useMessagesLocalStorage from "../../../hooks/useMessagesLocalStorage";

const SettingsPageLangContainer = (props: any) => {
  const messages = useMessagesLocalStorage(`/lang/pages/settings/`);

  if (messages === "") {
    return <div>loarding...</div>;
  } else {
    return <SettingsPage {...props} messages={JSON.parse(messages)} />;
  }
};

export default SettingsPageLangContainer;
