const qs = require("qs");
const request = require("request-promise");

const translate = async (sourceText, sourceLang, targetLang) => {
  var qst = qs.stringify({
    client: "gtx",
    sl: sourceLang,
    tl: targetLang,
    dt: "t",
    q: sourceText,
  });

  var options = {
    uri: "http://translate.googleapis.com/translate_a/single?" + qst,
    headers: {
      "User-Agent":
        "Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/49.0.2623.110 Safari/537.36",
    },
  };

  var result = await request(options);

  return JSON.parse(result)[0][0][0];
};

export default translate;
