import { Box, Flex } from "@chakra-ui/layout";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { decryptObject } from "../../utils/crypt";
import { capitalizeFirstLetter } from "../../utils/string";

const DisplayError = ({
  error,
  type = "default",
  messages,
}: {
  error: any;
  type?: string;
  messages: any;
}): any => {
  if (!error || !error.message) return null;

  if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors.length
  ) {
    return error.networkError.result.errors.map((error: any, i: number) => (
      <Box key={i}>
        <p>
          <strong>*ERC-0*</strong>:{" "}
          <span>{messages["no_internet_connection"]}</span>
          {error.message.replace("GraphQL error: ", "")}
        </p>
      </Box>
    ));
  }

  if (error.message.includes("Network error: Failed to fetch")) {
    return (
      <Flex>
        <Alert status="error">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>*ERC-1*</AlertTitle>
            <AlertDescription display="block">
              {
                messages[
                  "server_currently_under_maintenance_please_try_again_later"
                ]
              }
            </AlertDescription>
          </Box>
        </Alert>
      </Flex>
    );
  } else if (type === "login-errors") {
    const jsonObjError = JSON.parse(decryptObject(error.message));
    const { x_rate_limit_limit, x_rate_limit_remaining } = jsonObjError;
    return (
      <Flex>
        <Alert status="error">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>*ERC-2*</AlertTitle>
            <AlertDescription display="block">
              {capitalizeFirstLetter(messages["wrong_email_or_password_the_rate_limit_is"])}{" "}
              <b>{x_rate_limit_limit}</b>,{" "}
              {messages["your_rate_limit_remaining_is"]}{" "}
              <b>{x_rate_limit_remaining}</b>
            </AlertDescription>
          </Box>
        </Alert>
      </Flex>
    );
  } else {
    return (
      <Flex>
        <Alert status="error">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>*ERC-3*</AlertTitle>
            <AlertDescription display="block">
              {
                messages[
                  "server_currently_under_maintenance_please_try_again_later"
                ]
              }
            </AlertDescription>
          </Box>
        </Alert>
      </Flex>
    );
  }
};

DisplayError.defaultProps = {
  error: {},
};

DisplayError.propTypes = {
  error: PropTypes.object,
};

export default DisplayError;
