import React, { useState, useEffect } from "react";
import { Box, Flex, Heading } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { lang_list } from "../../lang/lang_list";
import { config } from "../../config";
import { LSK } from "../../lang/localStorageKey";
import { capitalizeFirstLetter } from "../../utils/string";

interface SettingsPageProps {
  messages: any;
}

const SettingsPage: React.FC<SettingsPageProps> = ({ messages }) => {
  const defLang = config.DEFAULT_LANG;
  const _dashboard_home_page_language = localStorage.getItem(LSK);
  // console.log(_dashboard_home_page_language);

  const [home_lang, set_home_lang] = useState(
    _dashboard_home_page_language || defLang
  );

  useEffect(() => {
    const dashboard_home_page_language = localStorage.getItem(LSK);
    if (dashboard_home_page_language) {
      // console.log(dashboard_home_page_language);
      localStorage.setItem(LSK, home_lang);
    } else {
      // console.log("set def");
      localStorage.setItem(LSK, defLang);
    }
  }, [home_lang, defLang]);

  return (
    <Box mb="64px">
      <Heading size="xl" pt="23px" pl="32px">
        {capitalizeFirstLetter(messages["settings"])}
      </Heading>

      <Flex alignItems="center" pt="32px" pl="32px">
        <Flex>{capitalizeFirstLetter(messages["language"])} :</Flex>
        <Flex pl="32px">
          <Select
            onChange={(e) => {
              set_home_lang(e.target.value);
              if (e.target.value !== home_lang) {
                window.location.reload();
              }
            }}
            defaultValue={home_lang}
            style={{ paddingLeft: "16px" }}
            variant="flushed"
          >
            {lang_list.map((el) => (
              <option key={el.code} value={el.code}>
                {el.label}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
    </Box>
  );
};

export default SettingsPage;
