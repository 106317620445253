import { Flex, Select, Input } from "@chakra-ui/react";
import React from "react";
import {
  getAttrOfItemBykey,
  getValueOfItemBykey,
} from "../../../../../utils/object";
import { string_filter_search_types } from "../../../constants";
import { FilterType } from "../../../types";
import { getDisabledState } from "../../../utils/getDisabledState";
import { updateFilterSearchType, updateFilterValue } from "../utils";

interface FilterModalInputTypeStringProps {
  messages: any;
  selected_filter_key: string;
  local_filters: FilterType[] | null;
  set_local_filters: React.Dispatch<React.SetStateAction<FilterType[] | null>>;
  on_filter: (local_filters: any, clear?: boolean | undefined) => void;
}

const FilterModalInputTypeString: React.FC<FilterModalInputTypeStringProps> = ({
  messages,
  selected_filter_key,
  local_filters,
  set_local_filters,
  on_filter,
}) => {
  const current_search_type = getAttrOfItemBykey(
    selected_filter_key,
    local_filters,
    "search_type"
  );

  const current_value = getValueOfItemBykey(selected_filter_key, local_filters);

  return (
    <Flex direction="column">
      <Flex w="100%" pb="8px">
        <Select
          defaultValue={current_search_type}
          w="100%"
          onChange={(e) =>
            e.target.value &&
            updateFilterSearchType(
              e.target.value,
              selected_filter_key,
              local_filters,
              set_local_filters
            )
          }
        >
          {string_filter_search_types.map((el) => (
            <option key={`key-${el}`} value={`${el}`}>
              {messages[el]}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex>
        <Input
          onKeyDown={(e) => {
            if (
              e.key === "Enter" &&
              !getDisabledState(selected_filter_key, local_filters)
            ) {
              on_filter(local_filters);
            }
          }}
          type="text"
          value={current_value || ""}
          onChange={(e) =>
            updateFilterValue(
              e.target.value,
              selected_filter_key,
              local_filters,
              set_local_filters
            )
          }
          variant="flushed"
          placeholder={`${getAttrOfItemBykey(
            selected_filter_key,
            local_filters,
            "name"
          )}..`}
        />
      </Flex>
    </Flex>
  );
};

export default FilterModalInputTypeString;
