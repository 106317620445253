import { Input } from "@chakra-ui/input";
import { Flex } from "@chakra-ui/layout";
import { Icon } from "@chakra-ui/react";

import React from "react";
import { MdFilterList } from "react-icons/md";

import { FilterType } from "./types";
import FilterWrapper from "./Wrapper";
import SearchItem from "./Search/Item";
import FilterDropdownMenu from "./DropdownMenu";
import FilterModalInput from "./ModalInput";
import { getAttrOfItemBykey } from "../../utils/object";
import useMessagesLocalStorage from "../../hooks/useMessagesLocalStorage";

interface FilterComponentProps {
  row_height: number;
  filters: FilterType[] | null;
  on_filter: (local_filters: any, clear?: boolean) => void;
  defaultSearch: any;
  dropdownTop: string;
  filterModalInputTop: string;
  filterModalInputLeft: string;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  row_height,
  filters,
  defaultSearch,
  on_filter,
  dropdownTop,
  filterModalInputTop,
  filterModalInputLeft,
}) => {
  const [is_filter_menu_closed, set_is_filter_menu_closed] =
    React.useState(true);

  const [is_filter_menu_input_closed, set_is_filter_menu_input_closed] =
    React.useState(true);

  const [selected_filter_key, set_selected_filter_key] = React.useState<
    string | null
  >(null);

  const [local_filters, set_local_filters] = React.useState<
    FilterType[] | null
  >(defaultSearch);

  React.useEffect(() => {
    return () => {
      set_selected_filter_key(null);
      set_local_filters([...defaultSearch]);
    };
  }, [defaultSearch]);

  const _messages = useMessagesLocalStorage(`/lang/components/filter/`);

  if (_messages.length < 2) {
    return <span>loading...</span>;
  } else {
    const messages = JSON.parse(_messages);

    return (
      <>
        {/* local_filters:{" "}
        <pre style={{ marginLeft: "550px" }}>
          {JSON.stringify(
            local_filters &&
              local_filters.filter(
                (el: any) => el.value !== null && el.value !== ""
              ),
            null,
            2
          )}
        </pre> */}
        <FilterWrapper row_height={row_height}>
          <Flex
            as="label"
            cursor="pointer"
            htmlFor="filter-input"
            minH={`${row_height}px`}
            p="8px"
            m="0px 8px 0px 16px"
          >
            <Icon color="gray" w="6" h="6" as={MdFilterList} />
          </Flex>
          <Flex w="100%" minH={`${row_height}px`}>
            <Flex
              flexWrap="wrap"
              flex="1"
              minH={`${row_height}px`}
              alignItems="center"
            >
              {filters
                ?.filter((el) => el.value !== null)
                ?.map((single_filter) => (
                  <SearchItem
                  messages={messages}
                    key={single_filter.key}
                    filter={single_filter}
                    set_is_filter_menu_input_closed={
                      set_is_filter_menu_input_closed
                    }
                    set_selected_filter_key={set_selected_filter_key}
                    filters={filters}
                    on_filter={on_filter}
                  />
                ))}

              <Flex flex="1">
                <Input
                  readOnly
                  position="relative"
                  flex="1"
                  pl="16px"
                  id="filter-input"
                  autoComplete="off"
                  onClick={() => {
                    set_is_filter_menu_closed(false);
                  }}
                  minH={`${row_height}px`}
                  outline="0"
                  variant="unstyled"
                  type="text"
                  name="filter"
                />

                {!is_filter_menu_closed && (
                  <FilterDropdownMenu
                    dropdownTop={dropdownTop}
                    filters={filters}
                    set_is_filter_menu_closed={set_is_filter_menu_closed}
                    set_is_filter_menu_input_closed={
                      set_is_filter_menu_input_closed
                    }
                    set_selected_filter_key={set_selected_filter_key}
                  />
                )}

                {selected_filter_key && !is_filter_menu_input_closed && (
                  <FilterModalInput
                    messages={messages}
                    filterModalInputTop={filterModalInputTop}
                    filterModalInputLeft={filterModalInputLeft}
                    selected_filter_name={getAttrOfItemBykey(
                      selected_filter_key,
                      local_filters,
                      "name"
                    )}
                    selected_filter_key={selected_filter_key}
                    set_local_filters={set_local_filters}
                    set_is_filter_menu_input_closed={
                      set_is_filter_menu_input_closed
                    }
                    filters={filters}
                    local_filters={local_filters}
                    on_filter={on_filter}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        </FilterWrapper>
      </>
    );
  }
};

export default FilterComponent;
