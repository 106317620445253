import React from "react";
import { Flex } from "@chakra-ui/layout";

interface Props {
  row_height: number;
}

const FilterWrapper: React.FC<Props> = ({ children, row_height }) => {
  return (
    <Flex
      borderBottomWidth=".125px"
      borderBottomStyle="solid"
      borderBottomColor="gray.200"
      alignItems="center"
      mt="23px"
      
      mb="23px"
      minH={`${row_height}px`}
    >
      {children}
    </Flex>
  );
};

export default FilterWrapper;
