import DisplayError from "..";
import useMessagesLocalStorage from "../../../hooks/useMessagesLocalStorage";

const DisplayErrorLangContainer = (props: any) => {
  const messages = useMessagesLocalStorage(`/lang/components/error/`);

  if (messages === "") {
    return <div>loarding...</div>;
  } else {
    return <DisplayError {...props} messages={JSON.parse(messages)} />;
  }
};

export default DisplayErrorLangContainer;
