import React, { useEffect, Fragment } from "react";
import NProgress from "nprogress";

interface NProgressProps {
  is_done?: boolean;
}

const NProgressComponent: React.FC<NProgressProps> = ({ is_done }) => {
  useEffect(() => {
    NProgress.configure({ trickleSpeed: 175 });

    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  if (is_done) {
    NProgress.done();
  }

  return <Fragment />;
};
export default NProgressComponent;
