import useMessagesLocalStorage from "../../hooks/useMessagesLocalStorage";
import LoginPage from "../Login.page";

const LoginPageLangContainer = () => {
  const messages = useMessagesLocalStorage(`/lang/auth/login/`);

  if (messages === "") {
    return <div>loarding...</div>;
  } else {
    return <LoginPage messages={JSON.parse(messages)} />;
  }
};

export default LoginPageLangContainer;
