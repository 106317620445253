import { CANCELED, COMPLETED, IN_PROGRESS, UPDATED } from "../../../constants";

export const string_filter_search_types = [
  "contains",
  "equal",
  "not_equal",
  "start_with",
  "end_with",
];

export const number_filter_search_types = [
  "equal",
  "not_equal",
  "gt",
  "gte",
  "lt",
  "lte",
  "in",
  "out",
];

export const date_filter_search_types = [
  "default",
  "equal",
  "not_equal",
  "gt",
  "gte",
  "lt",
  "lte",
  "in",
  "out",
];

export const status_filter_search_types = ["equal", "not_equal"];
export const input_travel_for_filter_search_types = ["leisure", "business"];

export const input_status_filter_search_types = [
  IN_PROGRESS,
  UPDATED,
  CANCELED,
  COMPLETED,
];
