import { Flex, Select } from "@chakra-ui/react";
import React from "react";
import useMessagesLocalStorage from "../../../../../hooks/useMessagesLocalStorage";
import getLangByCode from "../../../../../lang/getLangByCode";
import { lang_list } from "../../../../../lang/lang_list";
import {
  getAttrOfItemBykey,
  getValueOfItemBykey,
} from "../../../../../utils/object";
import {
  input_status_filter_search_types,
  input_travel_for_filter_search_types,
  status_filter_search_types,
} from "../../../constants";
import { FilterType } from "../../../types";
import { updateFilterSearchType, updateFilterValue } from "../utils";

interface FilterModalInputTypeSelectProps {
  messages: any;
  selected_filter_key: string;
  local_filters: FilterType[] | null;
  set_local_filters: React.Dispatch<React.SetStateAction<FilterType[] | null>>;
  on_filter: (local_filters: any, clear?: boolean | undefined) => void;
}

const formatOption = (
  val: string,
  key: string,
  messages: any,
  countries: any,
  phones: any
) => {
  switch (key) {
    case "status":
    case "travel_for":
      return messages[val];

    case "code_lang":
      return getLangByCode(val);

    case "country":
    case "business_country":
      return countries.filter((el: any) => el.code === val)[0].name;
    case "code_country_phone_number":
      return phones.filter((el: any) => el.code === val)[0].dial_code;

    default:
      return val;
  }
};

const FilterModalInputTypeSelect: React.FC<FilterModalInputTypeSelectProps> = ({
  messages,
  selected_filter_key,
  local_filters,
  set_local_filters,
  on_filter,
}) => {
  const current_search_type = getAttrOfItemBykey(
    selected_filter_key,
    local_filters,
    "search_type"
  );

  const current_value = getValueOfItemBykey(selected_filter_key, local_filters);

  const _countries = useMessagesLocalStorage(`/lang/components/countries/`);
  const _phones = useMessagesLocalStorage(`/lang/components/phones/`);

  if (_countries === "" || _phones === "") {
    return <div>loarding...</div>;
  } else {
    const countries = JSON.parse(_countries);
    const phones = JSON.parse(_phones);

    let search_type_options: any = [];
    let options: any = [];

    switch (selected_filter_key) {
      case "status":
        search_type_options = status_filter_search_types;
        options = input_status_filter_search_types;
        break;

      case "travel_for":
        search_type_options = status_filter_search_types;
        options = input_travel_for_filter_search_types;
        break;

      case "code_lang":
        search_type_options = status_filter_search_types;
        options = lang_list.map((el) => el.code);

        break;
      case "country":
      case "business_country":
        search_type_options = status_filter_search_types;
        options = countries.map((el: any) => el.code);
        break;

      case "code_country_phone_number":
        search_type_options = status_filter_search_types;
        options = phones.map((el: any) => el.code);
        break;

      default:
    }

    return (
      <Flex direction="column">
        <Flex w="100%" pb="8px">
          <Select
            defaultValue={current_search_type}
            w="100%"
            onChange={(e) =>
              e.target.value &&
              updateFilterSearchType(
                e.target.value,
                selected_filter_key,
                local_filters,
                set_local_filters
              )
            }
          >
            {search_type_options.map((el: any) => (
              <option key={`key-${el}`} value={`${el}`}>
                {messages[el]}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex w="100%" pb="8px">
          <Select
            w="100%"
            defaultValue={current_value}
            onChange={(e) =>
              e.target.value &&
              updateFilterValue(
                e.target.value,
                selected_filter_key,
                local_filters,
                set_local_filters
              )
            }
          >
            {!current_value && <option>{"-------------"}</option>}
            {options.map((el: any) => (
              <option key={`key-${el}`} value={`${el}`}>
                {formatOption(
                  el,
                  selected_filter_key,
                  messages,
                  countries,
                  phones
                )}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
    );
  }
};

export default FilterModalInputTypeSelect;
