import { Flex, Text } from "@chakra-ui/react";
import React from "react";

interface NoContentIllustrationProps {
  hint?: string;
}
const NoContentIllustration: React.FC<NoContentIllustrationProps> = ({
  hint,
}) => {
  return (
    <Flex w="100%" alignItems="center" direction="column" pt="32px">
      <Flex pt="200px">
        <img
          src="/assets/images/no_content_illustration_v0.svg"
          alt="no_content"
        />
      </Flex>
      <Flex direction="column" alignItems="center">
        <Text>No content available</Text>
        <b>{hint}</b>
      </Flex>
    </Flex>
  );
};

export default NoContentIllustration;
